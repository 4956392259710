import React from 'react';
import classnames from 'classnames';
import DealersTable from './DealersTable';
import LoadingSpinner from '../../components/LoadingSpinner';
import ExportEntries from '../../components/ExportEntries';
import Dropdown from '../../audi-ui-components/Dropdown';
import IconSearch from '../../audi-ui-components/icons/Search';
import { PERMISSIONS } from '../../constants';
import _debounce from 'lodash/debounce';
import { request } from '../../lib/apiRequestWrapper';

import { connect } from 'react-redux';
import { setSummaryFilters, setCurrentDealerId, setPageEntries } from '../../redux/Ui';
import { getDealerSummary } from '../../redux/Data';
const mapStateToProps = state => {
  return {
    userData: state.user.data,
    dealerSummary: state.data.dealerSummary,
    isLoadingSummary: state.data.isLoadingSummary,
    sortSummary: state.ui.sortSummary
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setSummaryFilters: (type, v) => {dispatch(setSummaryFilters(type, v));},
    setCurrentDealerId: (id) => {dispatch(setCurrentDealerId(id));},
    setPageEntries: (p) => {dispatch(setPageEntries(p));},
    getDealerSummary: (cb, errCb) => {dispatch(getDealerSummary(cb, errCb));}
  }
}

class ListDealers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileFiltersOpen: false,
      activeMobileFilter: null
    }
    this._debouncedFetch = _debounce(props.getDealerSummary, 1000);
  }

  componentDidMount() {
    this.props.getDealerSummary();
    this.props.setCurrentDealerId(null);
    this.props.setPageEntries(1);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.sortSummary !== prevProps.sortSummary
    ) {
      this._debouncedFetch();
    }
  }

  toggleMobileFilter = (n) => {
    if (this.state.mobileFiltersOpen && n === this.state.activeMobileFilter) {
      this.setState({
        activeMobileFilter: null,
        mobileFiltersOpen: false
      });
    } else {
      this.setState({
        activeMobileFilter: n,
        mobileFiltersOpen: true
      });
    }
  }

  handleClickDealer = (id) => {
    this.props.history.push(`/entries/${id}`);
  }

  render() {
    const {userData, dealerSummary} = this.props;
    const {activeMobileFilter} = this.state;
    if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) === -1) {
      return (
        <div>
          You do not have permission to view this page.
        </div>
      );
    }
    return (
      <main className="page-list">
        {this.props.isLoadingSummary &&
          <LoadingSpinner type={!dealerSummary || dealerSummary.length < 1 ? 'overlay' : 'fixed'}>Loading</LoadingSpinner>
        }

        <div className="row">

          <div className="filters col-12 col-medium-3 ps-medium-4 order-medium-last">
            <div className="d-medium-none mobile-filter-links">
              <button onClick={() => { this.toggleMobileFilter("sort"); }} className={classnames({"active": activeMobileFilter === "sort"})}>Sort</button>
              <button onClick={() => { this.toggleMobileFilter("export"); }} className={classnames({"active": activeMobileFilter === "export"})}>Export</button>
            </div>

            <div className={classnames("filter", {"open": activeMobileFilter === "sort"})}>
              <div className="pt-4 pb-2 d-none d-medium-block">Sort</div>
              <div className="filter-inner">
                <Dropdown name="sortSummary"
                  label="Sort dealer summaries"
                  value={this.props.sortSummary}
                  options={[
                    {value: "name asc", label: "Dealer name (A - Z)"},
                    {value: "name desc", label: "Dealer name (Z - A)"}
                  ]}
                  onChange={(v) => { this.props.setSummaryFilters("sort", v); }}
                  className="hide-floating-label has-icons"
                />
              </div>
            </div>

            <ExportEntries
              isOpen={activeMobileFilter === 'export'}
              sortEntries="dealerName asc, createDate desc"
            />
          </div>

          <div className="col-12 col-medium-9">
            <DealersTable
              data={dealerSummary}
              sort={this.props.sortSummary}
              onSort={(s) => { this.props.setSummaryFilters("sort", s); }}
              onClickRow={this.handleClickDealer}
              isLoading={this.props.isLoadingSummary}
            />
          </div>

        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDealers);
