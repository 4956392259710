import React from 'react';
import classnames from 'classnames';
import UsersTable from './UsersTable';
import LoadingSpinner from '../../components/LoadingSpinner';
import Dropdown from '../../audi-ui-components/Dropdown';
import CheckboxFieldset from '../../components/CheckboxFieldset';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';
import { ROLES, ROLES_LABELS, ROLES_LIST, PERMISSIONS } from '../../constants';
import _debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import { getUsers, getGroupedUsers } from '../../redux/Data';
import { setUserFilters } from '../../redux/Ui';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    users: state.data.users,
    groupedUsers: state.data.groupedUsers,
    isLoadingUsers: state.data.isLoadingUsers,
    sortUsers: state.ui.sortUsers,
    filterUsersRole: state.ui.filterUsersRole,
    filterUsersRoleLength: state.ui.filterUsersRole.length, // triggers rerender
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => {dispatch(getUsers());},
    getGroupedUsers: () => {dispatch(getGroupedUsers());},
    setUserFilters: (type, v) => {dispatch(setUserFilters(type, v));},
  }
}

class ListUsers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileFiltersOpen: false,
      activeMobileFilter: null
    }
    this._debouncedFetch = _debounce(props.getUsers, 1000);
    this._debouncedFetchG = _debounce(props.getGroupedUsers, 1000);
  }

  componentDidMount() {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
      this.props.getGroupedUsers();
    } else {
      this.props.getUsers();
    }
  }

  componentDidUpdate(prevProps) {
    const {userData} = this.props;
    if (
      this.props.sortUsers !== prevProps.sortUsers ||
      this.props.filterUsersRoleLength !== prevProps.filterUsersRoleLength
    ) {
      if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
        this._debouncedFetchG();
      } else {
        this._debouncedFetch();
      }
    }
  }

  toggleMobileFilter = (n) => {
    if (this.state.mobileFiltersOpen && n === this.state.activeMobileFilter) {
      this.setState({
        activeMobileFilter: null,
        mobileFiltersOpen: false
      });
    } else {
      this.setState({
        activeMobileFilter: n,
        mobileFiltersOpen: true
      });
    }
  }

  handleClick = (id) => {
    this.props.history.push(`/profile/${id}`);
  }

  render() {
    const {userData, users, groupedUsers} = this.props;
    const {activeMobileFilter} = this.state;
    if (PERMISSIONS.LIST_USERS.indexOf(userData.role) === -1) {
      return (<div>You do not have permission to view this page.</div>);
    }
    var roleOpts = ROLES_LIST.reduce(function(filtered, role, i) {
      if (ROLES_LIST.indexOf(userData.role) <= i) {
        filtered.push({value: `(role eq '${role}')`, label: ROLES_LABELS[role]});
      }
      return filtered;
    }, []);
    var spinnerType = "overlay";
    if ((users && users.length) || (groupedUsers && groupedUsers.length)) {
      spinnerType = "fixed";
    }
    return (
      <main className="page-users">
        {this.props.isLoadingUsers &&
          <LoadingSpinner type={spinnerType}>Loading</LoadingSpinner>
        }

        <div className="row">

          <div className="filters col-12 col-medium-3 ps-medium-4 order-medium-last">
            <div className="d-medium-none mobile-filter-links">
              {roleOpts.length > 1 &&
                <button onClick={() => { this.toggleMobileFilter("filter"); }} className={classnames({"active": activeMobileFilter === "filter"})}>Filter</button>
              }
              <button onClick={() => { this.toggleMobileFilter("sort"); }} className={classnames({"active": activeMobileFilter === "sort"})}>Sort</button>
            </div>

            <div className={classnames("filter", {"open": activeMobileFilter === "sort"})}>
              <div className="pt-4 pb-2 d-none d-medium-block">Sort</div>
              <div className="filter-inner">
                <Dropdown name="sortUsers"
                  label="Sort users"
                  value={this.props.sortUsers}
                  options={[
                    {value: "lastName asc", label: "User name (asc)", jsxLabel: (<>User name <IconUp small /></>)},
                    {value: "lastName desc", label: "User name (desc)", jsxLabel: (<>User name <IconDown small /></>)}
                  ]}
                  onChange={(v) => { this.props.setUserFilters("sort", v); }}
                  className="hide-floating-label has-icons"
                />
              </div>
            </div>

            {roleOpts.length > 1 && <div className={classnames("filter", {"open": activeMobileFilter === "filter"})}>
              <div className="pt-4 pb-2 d-none d-medium-block">Filter</div>
              <div className="filter-inner">
                <CheckboxFieldset name="filterUsersRole"
                  label="Role"
                  value={this.props.filterUsersRole}
                  options={roleOpts}
                  onChange={(v) => { this.props.setUserFilters("role", v); }}
                />
              </div>
            </div>}

          </div>

          <div className="col-12 col-medium-9">

            {PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1 &&
              <div>
                {groupedUsers && groupedUsers.length && groupedUsers.map((dealer, i) => {
                  if (dealer.users && dealer.users.length) {
                    return (
                      <div key={i}>
                        <p className="aui-headline-4 mt-4 mb-2">{dealer.name}</p>
                        <UsersTable
                          data={dealer.users}
                          sort={this.props.sortUsers}
                          onSort={(s) => { this.props.setUserFilters("sort", s); }}
                          onClickRow={this.handleClick}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            }

            {PERMISSIONS.LIST_DEALERS.indexOf(userData.role) === -1 &&
              <UsersTable
                data={users}
                sort={this.props.sortUsers}
                onSort={(s) => { this.props.setUserFilters("sort", s); }}
                onClickRow={this.handleClick}
              />
            }

          </div>

        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
