import {request} from '../lib/apiRequestWrapper';
import moment from 'moment';
import { STATUS_LIST_PREORDER } from '../constants';

const SET_LOADING_ENTRIES = "DATA/SET_LOADING_ENTRIES";
const SET_ENTRIES = "DATA/SET_ENTRIES";
const SET_LOADING_SUMMARY = "DATA/SET_LOADING_SUMMARY";
const SET_SUMMARY = "DATA/SET_SUMMARY";
const SET_DEALERS = "DATA/SET_DEALERS";
const SET_LOADING_DEALERS = "DATA/SET_LOADING_DEALERS";
const SET_USERS = "DATA/SET_USERS";
const SET_GROUPED_USERS = "DATA/SET_GROUPED_USERS";
const SET_LOADING_USERS = "DATA/SET_LOADING_USERS";
const SET_GROUPS = "DATA/SET_GROUPS";
const SET_LOADING_GROUPS = "DATA/SET_LOADING_GROUPS";
const SET_PAYOUTS = "DATA/SET_PAYOUTS";
const SET_LOADING_PAYOUTS = "DATA/SET_LOADING_PAYOUTS";
export const CLEAR_DATA = "DATA/CLEAR_DATA";

export function getEntries(dealerId, page) {
  return (dispatch, getState) => {
    dispatch({type: SET_LOADING_ENTRIES, payload: true});

    const ui = getState().ui;
    const user = getState().user;
    let orderBy = ui.sortEntries;
    let currentPage = page || ui.pageEntries;
    let skip = currentPage > 1 ? ui.pageSize * (currentPage - 1) : 0;

    let q = `?$top=${ui.pageSize}&$skip=${skip}&$count=true&$orderby=${orderBy}`;

    let filters = [];
    if (ui.filterEntriesTab === "new") {
      filters.push("usedCar eq false");
    }
    if (ui.filterEntriesTab === "used") {
      filters.push("usedCar eq true");
    }
    if (dealerId) {
      if (ui.filterEntriesTab === "preorder") {
        filters.push(`preferredDealerId eq ${dealerId}`);
      } else {
        filters.push(`dealerId eq ${dealerId}`);
      }
    }
    if (!dealerId) {
      if (ui.filterEntriesTab === "preorder") {
        let dId = user.data.dealerId;
        filters.push(`preferredDealerId eq ${dId}`);
      }
    }
    if (ui.filterEntriesStatus.length && ui.filterEntriesTab !== "preorder") {
      let s = ui.filterEntriesStatus.join(' or ');
      filters.push(`(${s})`);
    }
    if (ui.filterEntriesStatusPreorder.length > 0 && ui.filterEntriesStatusPreorder.length < STATUS_LIST_PREORDER.length && ui.filterEntriesTab === "preorder") {
      let s = ui.filterEntriesStatusPreorder.join(' or ');
      filters.push(`(${s})`);
    }
    if (ui.filterEntriesDateStart) {
      let ds = moment(ui.filterEntriesDateStart).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') ge ${ds}`);
    }
    if (ui.filterEntriesDateEnd) {
      let de = moment(ui.filterEntriesDateEnd).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') le ${de}`);
    }
    if (filters.length) {
      let f = filters.join(' and ');
      q = q.concat(`&$filter=${f}`);
    }
    let type = ui.filterEntriesTab === "preorder" ? "reservations" : "posts";
    return request(
      `${process.env.RAZZLE_API}/1/${type}${q}`
    ).then((data) => {
      dispatch({ type: SET_ENTRIES, payload: data.items, count: data.count });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_ENTRIES, payload: false });
    });

  };
}

export function getDealerSummary(cb, errCb) {
  return (dispatch, getState) => {
    dispatch({type: SET_LOADING_SUMMARY, payload: true});

    const ui = getState().ui;
    let orderBy = ui.sortSummary;

    let q = `?$orderby=${orderBy}`;

    return request(
      `${process.env.RAZZLE_API}/1/posts/summaryReport${q}`
    ).then((data) => {
      dispatch({ type: SET_SUMMARY, payload: data.items });
      if (typeof cb === 'function') { cb(data); }
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_SUMMARY, payload: false });
      if (typeof errCb === 'function') { errCb(error); }
    });

  };
}

export function getDealers(cb) {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING_DEALERS, payload: true });

    return request(
      `${process.env.RAZZLE_API}/1/users/dealers`
    ).then((res) => {
      let opts = [];
      for (let i=0; i<res.length; i++) {
        opts.push({value: res[i].id, label: res[i].name});
      }
      dispatch({ type: SET_DEALERS, payload: res, opts: opts });
      if (typeof cb === 'function') {
        cb(res);
      }
    }).catch((error) => {
      dispatch({ type: SET_LOADING_DEALERS, payload: false });
    });

  };
}

export function getUsers() {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING_USERS, payload: true });

    const ui = getState().ui;
    let orderBy = ui.sortUsers;

    let q = `?$orderby=${orderBy}`;

    let filters = [];
    if (ui.filterUsersRole.length) {
      let s = ui.filterUsersRole.join(' or ');
      filters.push(`(${s})`);
    }
    if (filters.length) {
      let f = filters.join(' and ');
      q = q.concat(`&$filter=${f}`);
    }

    return request(
      `${process.env.RAZZLE_API}/1/users${q}`
    ).then((res) => {
      // console.log(res);
      dispatch({ type: SET_USERS, payload: res.items });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_USERS, payload: false });
    });

  }
}

export function getGroupedUsers() {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING_USERS, payload: true });

    const ui = getState().ui;
    let orderBy = ui.sortUsers;

    let filters = [];
    if (ui.filterUsersRole.length) {
      let s = ui.filterUsersRole.join(' or ');
      filters.push(`(${s})`);
    }
    let f = "";
    if (filters.length) {
      f = `$filter=${filters.join(' and ')}`;
    }

    let q = `?$orderby=name asc&$expand=users($orderby=${ui.sortUsers};${f})`;

    return request(
      `${process.env.RAZZLE_API}/1/users/dealer${q}`
    ).then((res) => {
      // console.log(res);
      dispatch({ type: SET_GROUPED_USERS, payload: res });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_USERS, payload: false });
    });

  }
}

export function getGroups() {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING_GROUPS, payload: true });

    return request(
      `${process.env.RAZZLE_API}/1/groups`
    ).then((res) => {
      // console.log(res);
      dispatch({ type: SET_GROUPS, payload: res.items });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_GROUPS, payload: false });
    });

  }
}

export function getPayouts(page) {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING_PAYOUTS, payload: true });

    const ui = getState().ui;
    let orderBy = ui.sortEntries;
    let currentPage = page || ui.pagePayouts;
    let skip = currentPage > 1 ? ui.pageSize * (currentPage - 1) : 0;

    let q = `?$top=${ui.pageSize}&$skip=${skip}&$count=true&$orderby=createDate desc`;

    let filters = [];
    if (ui.filterPayoutsDateStart) {
      let ds = moment(ui.filterPayoutsDateStart).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') ge ${ds}`);
    }
    if (ui.filterPayoutsDateEnd) {
      let de = moment(ui.filterPayoutsDateEnd).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') le ${de}`);
    }
    if (filters.length) {
      let f = filters.join(' and ');
      q = q.concat(`&$filter=${f}`);
    }

    return request(
      `${process.env.RAZZLE_API}/1/stripe/payouts${q}`
    ).then((data) => {
      dispatch({ type: SET_PAYOUTS, payload: data.items, count: data.count });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_PAYOUTS, payload: false });
    });

  }
}

export function searchPayouts(searchString) {
  return (dispatch, getState) => {
    return request(
      `${process.env.RAZZLE_API}/1/stripe/payout/${searchString}`
    ).then((data) => {
      dispatch({ type: SET_PAYOUTS, payload: [data], count: 1 });
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_LOADING_PAYOUTS, payload: false });
    });
  }
}

const defaultState = {
  entries: null,
  entriesCount: 0,
  dealerSummary: null,
  dealers: null,
  dealerOpts: [],
  users: null,
  groupedUsers: null,
  groups: null,
  payouts: null,
  payoutsCount: 0,
  isLoadingEntries: false,
  isLoadingSummary: false,
  isLoadingDealers: false,
  isLoadingUsers: false,
  isLoadingGroups: false,
  isLoadingPayouts: false
}

export function dataReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ENTRIES:
      return Object.assign({}, state, {
        entries: action.payload,
        entriesCount: action.count,
        isLoadingEntries: false
      });
    case SET_LOADING_ENTRIES:
      return Object.assign({}, state, {
        isLoadingEntries: action.payload
      });
    case SET_SUMMARY:
      return Object.assign({}, state, {
        dealerSummary: action.payload,
        isLoadingSummary: false
      });
    case SET_LOADING_SUMMARY:
      return Object.assign({}, state, {
        isLoadingSummary: action.payload
      });
    case SET_DEALERS:
      return Object.assign({}, state, {
        dealers: action.payload,
        dealerOpts: action.opts,
        isLoadingDealers: false
      });
    case SET_LOADING_DEALERS:
      return Object.assign({}, state, {
        isLoadingDealers: action.payload
      });
    case SET_GROUPS:
      return Object.assign({}, state, {
        groups: action.payload,
        isLoadingGroups: false
      });
    case SET_LOADING_GROUPS:
      return Object.assign({}, state, {
        isLoadingGroups: action.payload
      });
    case SET_USERS:
      return Object.assign({}, state, {
        users: action.payload,
        isLoadingUsers: false
      });
    case SET_GROUPED_USERS:
      return Object.assign({}, state, {
        groupedUsers: action.payload,
        isLoadingUsers: false
      });
    case SET_LOADING_USERS:
      return Object.assign({}, state, {
        isLoadingUsers: action.payload
      });
    case SET_PAYOUTS:
      return Object.assign({}, state, {
        payouts: action.payload,
        payoutsCount: action.count,
        isLoadingPayouts: false
      });
    case SET_LOADING_PAYOUTS:
      return Object.assign({}, state, {
        isLoadingPayouts: action.payload
      });
    case CLEAR_DATA:
      return defaultState;
    default:
      return state;
  }
}
