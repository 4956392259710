import React from 'react';
import classnames from 'classnames';
import FormField from '../components/FormField';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import IconCancel from '../audi-ui-components/icons/Cancel';
import { Formik, Form } from 'formik';
import { FIELD_CHECKBOXES, FIELD_DROPDOWN, PERMISSIONS, ROLES, ROLES_OPTS, REGIONS_OPTS } from '../constants';
import { request } from '../lib/apiRequestWrapper';
import _find from 'lodash/find';

import { connect } from 'react-redux';
import { getDealers } from '../redux/Data';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    dealerOpts: state.data.dealerOpts
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDealers: (cb) => {dispatch(getDealers(cb));},
  }
}

class Group extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      group: null,
      isSubmitting: false,
      success: false,
      serverError: null
    }
  }

  componentDidMount() {
    const {userData, match} = this.props;
    if (match.params.groupId && match.params.groupId !== "new") {
      // fetch group
      request(
        `${process.env.RAZZLE_API}/1/groups/${match.params.groupId}`
      ).then((data) => {
        console.log(data);
        this.setState({isLoading: false, group: data});
      }).catch((error) => {
        console.error(error);
        let msg = "An error has occured";
        if (error.body && error.body.message) {
          msg = error.body.message;
        }
        this.setState({isLoading: false, serverError: msg});
      });
    } else {
      this.setState({isLoading: false});
    }
    if (!this.props.dealerOpts || !this.props.dealerOpts.length) {
      this.props.getDealers();
    }
  }

  handleClose = () => {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_GROUPS.indexOf(userData.role) > -1) {
      this.props.history.push("/groups");
    } else {
      if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
        this.props.history.push("/dealers");
      } else {
        this.props.history.push("/entries");
      }
    }
  }

  handleSubmit = (values, formikBag) => {
    const {userData, match} = this.props;
    this.setState({isSubmitting: true, success: false, serverError: null});

    var uri = "";
    var requestOpts = {};
    var gId = match.params.groupId;

    if (gId === "new") {
      // create group
      uri = `${process.env.RAZZLE_API}/1/groups`;
      requestOpts = {
        method: 'POST',
        body: JSON.stringify(values),
      };
    } else {
      // update group
      uri = `${process.env.RAZZLE_API}/1/groups/${gId}`;
      requestOpts = {
        method: 'PATCH',
        body: JSON.stringify(values),
      };
    }

    return request(
      uri,
      requestOpts
    ).then((data) => {
      console.log(data);
      this.setState({isSubmitting: false, success: true});
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    });
  }

  render() {
    const {userData, match} = this.props;
    const {group} = this.state;

    if (match.params.groupId === "new" && PERMISSIONS.ADD_GROUP.indexOf(userData.role) === -1) {
      return (
        <div>You do not have permission to create new groups.</div>
      );
    } else if (PERMISSIONS.LIST_GROUPS.indexOf(userData.role) === -1) {
      return (
        <div>You do not have permission to view this page.</div>
      );
    }

    var initialValues = {name: "", dealerIds: []};
    var action = ""; // new / update
    var btnLabel = "";
    if (match.params.groupId === "new") {
      action = "new";
      btnLabel = "Add new group";
    } else {
      action = "update";
      btnLabel = "Edit group";
      if (group !== null) {
        initialValues = {
          name: group.name,
          dealerIds: group.dealerIds
        };
      }
    }
    return (
      <main className="page-group">
        {(this.state.isLoading || this.state.isSubmitting) &&
          <LoadingSpinner />
        }

        <div className="row">
          <div className="col-12 col-medium-9">

            <div className="text-end mb-2">
              <button onClick={this.handleClose} className="plain-button detail-close">
                <span className="svg-wrapper"><IconCancel large /></span><br />close
              </button>
            </div>

            <Formik onSubmit={this.handleSubmit} initialValues={initialValues} enableReinitialize onReset={() => { this.setState({success: false, serverError: null}); }}>
              {formikBag => (
                <Form style={{maxWidth: "700px"}}>

                  {this.state.success && <>
                    {action === "new" && <>
                      <p className="aui-color-text-green aui-headline-5 mt-5 mb-3">
                        <b>The new group has been added</b>
                      </p>
                      <p className="mb-5">
                        <Button variant="secondary" onClick={formikBag.handleReset}>Add another group</Button>
                      </p>
                    </>}
                    {action === "update" && <p className="aui-color-text-green aui-headline-5 my-5"><b>The group has been updated</b></p>}
                  </>}

                  <FormField name="name" label="Group name" formikBag={formikBag} wrapperClassName="mb-4" />

                  <FormField name="dealerIds"
                    label={<>Dealers in group {formikBag.values.dealerIds.length > 0 && <>({formikBag.values.dealerIds.length})</>}</>}
                    fieldType={FIELD_CHECKBOXES}
                    formikBag={formikBag}
                    options={this.props.dealerOpts}
                  />

                  {this.state.serverError && <p className="aui-color-text-red my-3">{this.state.serverError}</p>}

                  <p className="my-5">
                    {!(this.state.success && action === "new") && <Button type="submit" variant="primary">{btnLabel}</Button>}
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Group);
