import React from 'react';
import classnames from 'classnames';
import EntryTable from './ListEntries/EntryTable';
import DealersTable from './ListDealers/DealersTable';
import UsersTable from './ListUsers/UsersTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { ROLES, ROLES_LABELS, PERMISSIONS } from '../constants';
// import _debounce from 'lodash/debounce';
import { request } from '../lib/apiRequestWrapper';

import { connect } from 'react-redux';
import { setSearch } from '../redux/Ui';
const mapStateToProps = state => {
  return {
    userData: state.user.data,
    searchText: state.ui.search,
    pageSize: state.ui.pageSize
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setSearch: (s) => {dispatch(setSearch(s));}
  }
}

class SearchResults extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      entries: null,
      countEntries: 0,
      sortEntries: "createDate desc",
      pageEntries: 1,
      isSearchingEntries: false,

      dealerSummaries: null,
      sortSummaries: "name asc",
      isSearchingSummaries: false,

      users: null,
      sortUsers: "lastName asc",
      isSearchingUsers: false
    }
    // this._debouncedFetch = _debounce(this.search, 1000);
  }

  componentDidMount() {
    if (this.props.searchText) {
      this.setState({isSearchingEntries: true, isSearchingUsers: true, isSearchingSummaries: true});
      this.searchEntries();
      this.searchUsers();
      this.searchSummaries();
    }
  }

  componentWillUnmount() {
    this.props.setSearch("");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchText && prevProps.searchText !== this.props.searchText) {
      this.setState({isSearchingEntries: true, isSearchingUsers: true, isSearchingSummaries: true});
      this.searchEntries();
      this.searchUsers();
      this.searchSummaries();
    } else {
      if (prevState.sortEntries !== this.state.sortEntries || prevState.pageEntries !== this.state.pageEntries) {
        this.setState({isSearchingEntries: true});
        this.searchEntries();
      }
      if (prevState.sortUsers !== this.state.sortUsers) {
        this.setState({isSearchingUsers: true});
        this.searchUsers()
      }
      if (prevState.sortSummaries !== this.state.sortSummaries) {
        this.setState({isSearchingSummaries: true});
        this.searchSummaries();
      }
    }
  }

  searchEntries = (_page) => {
    const {searchText, pageSize} = this.props;
    const {sortEntries, pageEntries, isSearchingEntries} = this.state;
    // if (!isSearchingEntries) {
    //   this.setState({isSearchingEntries: true});
    // }
    let _searchText = searchText.toLowerCase();
    let currentPage = _page || pageEntries;
    let skip = currentPage > 1 ? pageSize * (currentPage - 1) : 0;
    let searchFilter = `
      contains(cast(stockNumber, 'Edm.String'), '${_searchText}')
      or contains(tolower(model), '${_searchText}')
      or contains(tolower(customer/firstName), '${_searchText}')
      or contains(tolower(customer/lastName), '$${_searchText}')
      or contains(tolower(customer/email), '${_searchText}')`;
    let q = `?$top=${pageSize}&$skip=${skip}&$count=true&$orderby=${sortEntries}&$filter=${searchFilter}`;
    return request(
      `${process.env.RAZZLE_API}/1/posts${q}`
    ).then((data) => {
      this.setState({
        entries: data.items,
        countEntries: data.count,
        pageEntries: currentPage,
        isSearchingEntries: false
      });
    }).catch((error) => {
      console.error(error);
      this.setState({isSearchingEntries: false});
    });
  }

  searchUsers = () => {
    const {userData, searchText} = this.props;
    if (PERMISSIONS.LIST_USERS.indexOf(userData.role) > -1) {
      const {sortUsers} = this.state;
      let _searchText = searchText.toLowerCase();
      let searchFilter = `
        contains(tolower(firstName), '${_searchText}')
        or contains(tolower(lastName), '${_searchText}')
        or contains(tolower(email), '${_searchText}')`;
      let q = `?$orderby=${sortUsers}&$filter=${searchFilter}`;
      return request(
        `${process.env.RAZZLE_API}/1/users${q}`
      ).then((data) => {
        this.setState({
          users: data.items,
          isSearchingUsers: false
        });
      }).catch((error) => {
        console.error(error);
        this.setState({isSearchingUsers: false});
      });
    } else {
      this.setState({isSearchingUsers: false});
    }
  }

  searchSummaries = () => {
    const {userData, searchText} = this.props;
    if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
      const {sortSummaries} = this.state;
      let _searchText = searchText.toLowerCase();
      let searchFilter = `contains(tolower(name), '${_searchText}')`;
      let q = `?$orderby=${sortSummaries}&$filter=${searchFilter}`;
      return request(
        `${process.env.RAZZLE_API}/1/posts/summaryReport${q}`
      ).then((data) => {
        this.setState({
          dealerSummaries: data.items,
          isSearchingSummaries: false
        });
      }).catch((error) => {
        console.error(error);
        this.setState({isSearchingSummaries: false});
      });
    } else {
      this.setState({isSearchingSummaries: false});
    }
  }

  render() {
    const {searchText} = this.props;
    const {
      entries, users, dealerSummaries,
      isSearchingEntries, isSearchingUsers, isSearchingSummaries
    } = this.state;
    const isSearchingAll = entries === null && users === null && dealerSummaries === null && isSearchingEntries;
    return (
      <main className="page-search-results">
        {!searchText && <p className="aui-color-text-red mb-5">Please enter a search term</p>}
        {searchText && <p className="aui-headline-2 mb-5">Search results for <b>{searchText}</b></p>}

        {isSearchingAll && <LoadingSpinner type="placeholder">Searching</LoadingSpinner>}

        <div className="row">
          <div className="col-12 col-medium-9">

            {(entries && entries !== null) && <div className="mb-5">
              {isSearchingEntries && <LoadingSpinner type="overlay" />}
              <p className="aui-headline-4 mb-2">Orders / Enquiries</p>
              <EntryTable
                data={entries}
                sort={this.state.sortEntries}
                onSort={(s) => { this.setState({sortEntries: s}); }}
                onClickRow={(id) => { this.props.history.push(`/entry/${id}`); }}
                onClickPage={(idx) => { this.setState({pageEntries: idx + 1}); }}
                page={this.state.pageEntries}
                count={this.state.countEntries}
                pageSize={this.props.pageSize}
                isLoading={isSearchingEntries}
              />
            </div>}

            {(dealerSummaries && dealerSummaries !== null) && <div className="mb-5">
              {isSearchingSummaries && <LoadingSpinner type="overlay" />}
              <p className="aui-headline-4 mb-2">Dealers</p>
              <DealersTable
                data={dealerSummaries}
                sort={this.state.sortSummaries}
                onSort={(s) => { this.setState({sortSummaries: s}); }}
                onClickRow={(id) => { this.props.history.push(`/entries/${id}`); }}
                isLoading={isSearchingSummaries}
              />
            </div>}

            {(users && users !== null) && <div className="mb-5">
              {isSearchingUsers && <LoadingSpinner type="overlay" />}
              <p className="aui-headline-4 mb-2">Users</p>
              <UsersTable
                data={users}
                sort={this.state.sortUsers}
                onSort={(s) => { this.setState({sortUsers: s}); }}
                onClickRow={(id) => { this.props.history.push(`/profile/${id}`); }}
                isLoading={isSearchingUsers}
              />
            </div>}

          </div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
