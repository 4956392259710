import React from 'react';
import classnames from 'classnames';
import FormField from '../components/FormField';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import Modal from '../audi-ui-components/Modal';
import IconCancel from '../audi-ui-components/icons/Cancel';
import IconErase from '../audi-ui-components/icons/Erase';
import { Formik, Form } from 'formik';
import {
  FIELD_SELECT, FIELD_CHECKBOX, FIELD_CHECKBOXES,
  PERMISSIONS,
  ROLES, ROLES_LIST, ROLES_LABELS,
  REGIONS_OPTS
} from '../constants';
import { request } from '../lib/apiRequestWrapper';
import _find from 'lodash/find';

import { connect } from 'react-redux';
import { getDealers, getGroups } from '../redux/Data';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    dealerOpts: state.data.dealerOpts,
    groups: state.data.groups,
    isLoadingGroups: state.data.isLoadingGroups
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getDealers: () => {dispatch(getDealers());},
    getGroups: () => {dispatch(getGroups());}
  }
}

class User extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      user: null,
      isSubmitting: false,
      isDeleting: false,
      success: false,
      serverError: null,
      deleteConfirmOpen: false
    }
  }

  componentDidMount() {
    const {userData, match} = this.props;
    if (match.params.userId && match.params.userId !== "new" && match.params.userId !== userData.userId) {
      // fetch user
      request(
        `${process.env.RAZZLE_API}/1/users/${match.params.userId}`
      ).then((data) => {
        console.log(data);
        this.setState({isLoading: false, user: data});
      }).catch((error) => {
        console.error(error);
        let msg = "An error has occured";
        if (error.body && error.body.message) {
          msg = error.body.message;
        }
        this.setState({isLoading: false, serverError: msg});
      });
    } else {
      this.setState({isLoading: false});
    }
    if (!this.props.dealerOpts || !this.props.dealerOpts.length) {
      this.props.getDealers();
    }
    if (PERMISSIONS.LIST_GROUPS.indexOf(userData.role) > -1) {
      this.props.getGroups();
    }
  }

  handleClose = () => {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_USERS.indexOf(userData.role) > -1) {
      this.props.history.push("/users");
    } else {
      if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
        this.props.history.push("/dealers");
      } else {
        this.props.history.push("/entries");
      }
    }
  }

  handleSubmit = (values, formikBag) => {
    const {userData, match} = this.props;
    this.setState({isSubmitting: true, success: false, serverError: null});

    var uri = "";
    var requestOpts = {};
    var uId = match.params.userId || userData.userId;

    if (values.dealerId) {
      // get dealer name
      let d = _find(this.props.dealerOpts, {value: +values.dealerId}) || {};
      values.dealerName = d.label;
    }

    if (uId === "new") {
      // create user
      uri = `${process.env.RAZZLE_API}/1/users`;
      requestOpts = {
        method: 'POST',
        body: JSON.stringify(values),
      };
    } else {
      // update user
      uri = `${process.env.RAZZLE_API}/1/users/${uId}`;
      requestOpts = {
        method: 'PATCH',
        body: JSON.stringify(values),
      };
    }

    return request(
      uri,
      requestOpts
    ).then((data) => {
      console.log(data);
      this.setState({isSubmitting: false, success: true});
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    });
  }

  deleteUser = () => {
    this.setState({isDeleting: true});
    return request(
      `${process.env.RAZZLE_API}/1/users/${this.props.match.params.userId}`,
      {method: "DELETE"}
    ).then(() => {
      // user deleted, return to list
      this.handleClose();
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({serverError: msg, deleteConfirmOpen: false, isDeleting: false});
    });
  }

  render() {
    const {userData, match} = this.props;
    const {user} = this.state;

    if (match.params.userId === "new" && PERMISSIONS.ADD_USER.indexOf(userData.role) === -1) {
      return (
        <div>You do not have permission to create new users.</div>
      );
    }

    var initialValues = {};
    var action = ""; // new / update / updateOwn
    var btnLabel = "";
    var rolesOpts = [];
    if (!match.params.userId || match.params.userId === userData.userId) {
      action = "updateOwn";
      btnLabel = "Update my profile";
      rolesOpts.push({label: ROLES_LABELS[userData.role], value: userData.role});
      initialValues = userData;
    } else if (match.params.userId === "new") {
      action = "new";
      btnLabel = "Add new user";
      for (let i=0; i<ROLES_LIST.length; i++) {
        let r = ROLES_LIST[i];
        if (PERMISSIONS.ASSIGN_ROLE[r].indexOf(userData.role) > -1) {
          rolesOpts.push({label: ROLES_LABELS[r], value: r});
        }
      }
      if (rolesOpts.length === 1) {
        initialValues.role = rolesOpts[0].value;
      }
      if (PERMISSIONS.ASSIGN_ANY_LOCATION.indexOf(userData.role) === -1) {
        initialValues.dealerId = userData.dealerId;
        initialValues.groupId = userData.groupId;
        initialValues.region = userData.region;
      }
      initialValues.view = userData.view;
    } else {
      action = "update";
      btnLabel = "Edit user";
      if (user !== null) {
        initialValues = user;
        for (let i=0; i<ROLES_LIST.length; i++) {
          let r = ROLES_LIST[i];
          if (PERMISSIONS.ASSIGN_ROLE[r].indexOf(userData.role) > -1) {
            rolesOpts.push({label: ROLES_LABELS[r], value: r});
          } else if (r === user.role) {
            rolesOpts.push({label: ROLES_LABELS[r], value: r, disabled: true});
          }
        }
      }
    }

    var groupOpts = [];
    if (this.props.groups) {
      for (let i=0; i<this.props.groups.length; i++) {
        groupOpts.push({value: this.props.groups[i].groupId, label: this.props.groups[i].name})
      }
    }
    if (userData.role === ROLES.GROUP_MNGR && userData.groupId && userData.groupName) {
      groupOpts.push({value: userData.groupId, label: userData.groupName});
    }
    return (
      <main className="page-user">
        {(this.state.isLoading || this.state.isSubmitting) &&
          <LoadingSpinner />
        }

        <div className="row">
          <div className="col-12 col-medium-9">

            <div className="text-end mb-2">
              <button onClick={this.handleClose} className="plain-button detail-close">
                <span className="svg-wrapper"><IconCancel large /></span><br />close
              </button>
            </div>

            <Formik onSubmit={this.handleSubmit} initialValues={initialValues} enableReinitialize onReset={() => { this.setState({success: false, serverError: null}); }}>
              {formikBag => (
                <Form style={{maxWidth: "700px"}}>

                  {this.state.success && <>
                    {action === "new" && <>
                      <p className="aui-color-text-green aui-headline-5 mt-5 mb-3">
                        <b>The new user has been added</b>
                      </p>
                      <p className="mb-5">
                        <Button variant="secondary" onClick={formikBag.handleReset}>Add another user</Button>
                      </p>
                    </>}
                    {action === "updateOwn" && <p className="aui-color-text-green aui-headline-5 my-5"><b>Your profile has been updated</b></p>}
                    {action === "update" && <p className="aui-color-text-green aui-headline-5 my-5"><b>The user has been updated</b></p>}
                  </>}

                  <FormField name="email" label="Email Address" formikBag={formikBag} />
                  <FormField name="firstName" label="First name" formikBag={formikBag} />
                  <FormField name="lastName" label="Last name" formikBag={formikBag} />

                  <FormField name="role"
                    label="Role"
                    fieldType={FIELD_SELECT}
                    formikBag={formikBag}
                    options={rolesOpts}
                    disabled={action === "updateOwn"}
                  />

                  {(formikBag.values.role === ROLES.DEALER || formikBag.values.role === ROLES.DEALER_ADMIN) &&
                    <FormField name="dealerId"
                      label="Dealership"
                      fieldType={FIELD_SELECT}
                      formikBag={formikBag}
                      options={this.props.dealerOpts}
                      disabled={action === "updateOwn" || PERMISSIONS.ASSIGN_ANY_LOCATION.indexOf(userData.role) === -1}
                    />
                  }
                  {formikBag.values.role === ROLES.REG_MNGR &&
                    <FormField name="region"
                      label="Region"
                      fieldType={FIELD_SELECT}
                      formikBag={formikBag}
                      options={REGIONS_OPTS}
                      disabled={action === "updateOwn" || PERMISSIONS.ASSIGN_ANY_LOCATION.indexOf(userData.role) === -1}
                    />
                  }
                  {formikBag.values.role === ROLES.GROUP_MNGR &&
                    <FormField name="groupId"
                      label="Group"
                      fieldType={FIELD_SELECT}
                      formikBag={formikBag}
                      options={groupOpts}
                      disabled={action === "updateOwn" || PERMISSIONS.ASSIGN_ANY_LOCATION.indexOf(userData.role) === -1}
                    />
                  }

                  <FormField name="view"
                    label="Additional permissions"
                    fieldType={FIELD_CHECKBOXES}
                    formikBag={formikBag}
                    options={[
                        {label: "New Cars", value: "new", disabled: PERMISSIONS.ASSIGN_VIEW.indexOf(userData.role) === -1},
                        {label: "Used Cars", value: "used", disabled: PERMISSIONS.ASSIGN_VIEW.indexOf(userData.role) === -1}
                    ]}
                    wrapperClassName="mb-2 pt-3"
                />

                  <FormField name="allowAlertNotifications" label="Allow alert notifications" fieldType={FIELD_CHECKBOX} formikBag={formikBag} wrapperClassName="mb-2 pt-3" />

                  {(action === "new" || action === "updateOwn" || PERMISSIONS.EDIT_USER.indexOf(userData.role) > -1) &&
                    <FormField name="password" label="Password" type="password" formikBag={formikBag} />
                  }

                  {this.state.serverError && <p className="aui-color-text-red my-3">{this.state.serverError}</p>}

                  <p className="my-5">
                    {!(this.state.success && action === "new") && (action === "updateOwn" || PERMISSIONS.EDIT_USER.indexOf(userData.role) > -1 || PERMISSIONS.ADD_USER.indexOf(userData.role) > -1) &&
                      <Button type="submit" variant="primary" disabled={this.state.isSubmitting}>{btnLabel}</Button>
                    }
                    {` `}
                    {(PERMISSIONS.DELETE_USER.indexOf(userData.role) > -1 && action === "update") &&
                      <Button variant="secondary" icon={<IconErase small />} onClick={() => { this.setState({deleteConfirmOpen: true}); }}>Deactivate user</Button>
                    }
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Modal isActive={this.state.deleteConfirmOpen} variant="layer">
          {this.state.isDeleting && <LoadingSpinner />}
          <p className="aui-headline-5 mb-5">Are you sure you want to delete this user?</p>
          <Button variant="primary" icon={<IconErase small />} onClick={this.deleteUser}>Yes, delete user</Button>
          {` `}
          <Button variant="secondary" onClick={() => { this.setState({deleteConfirmOpen: false}); }}>Cancel</Button>
        </Modal>

      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
