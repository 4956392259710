// Store
import { thunk } from './middleware';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

// Reducers
import { userReducer } from './User';
import { dataReducer } from './Data';
import { uiReducer } from './Ui';

const combinedReducers = combineReducers({
  user: userReducer,
  data: dataReducer,
  ui: uiReducer,
});
let devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const createAppStore = (initialState) => createStore(combinedReducers, initialState, composeEnhancers(applyMiddleware(thunk)));

// https://github.com/zalmoxisus/redux-devtools-extension
