import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M4.5,10.1 L4.5,16.5 C4.5,17.6 5.4,18.5 6.5,18.5 L18.5,18.5 L18.5,10.1"/>
          <path d="M20.8 11.8L11.5 4.6 2.2 11.8"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M39.5,19.7 L39.5,39.5 L10.6,39.5 C8.9,39.5 7.6,38.2 7.5,36.5 L7.5,19.7"/>
        <path d="M44.5 23.5L23.5 7.4 2.5 23.5"/>
      </g>
    </svg>
  );
};
