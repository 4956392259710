import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Cancel from './icons/Cancel';
import _debounce from 'lodash/debounce';

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.closeRef = React.createRef();
    this.bodyRef = React.createRef();
    this._debouncedUpdate = _debounce(this.repositionCloseButton, 400);
  }

  componentDidMount() {
    if (this.props.isActive) {
      // open on mount
      if(document.body.className.indexOf('aui-modal-open') === -1) {
        setTimeout(() => {document.body.className += ' aui-modal-open';}, 10);
      }
      if (this.props.variant === 'window' && this.props.closeButton) {
        setTimeout(this.repositionCloseButton, 400);
        window.addEventListener("resize", this._debouncedUpdate, false);
      }
      window.addEventListener("keydown", this.handleEsc, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive) {
      // open
      if(document.body.className.indexOf('aui-modal-open') === -1) {document.body.className += ' aui-modal-open';}
      if (this.props.variant === 'window' && this.props.closeButton) {
        setTimeout(this.repositionCloseButton, 400);
        window.addEventListener("resize", this._debouncedUpdate, false);
      }
      window.addEventListener("keydown", this.handleEsc, false);
    }
    if (prevProps.isActive && !this.props.isActive) {
      // close
      if(document.body.className.indexOf('aui-modal-open') !== -1) {document.body.className = document.body.className.replace(/\baui-modal-open\b/g, "");}
      window.removeEventListener("resize", this._debouncedUpdate, false);
      window.removeEventListener("keydown", this.handleEsc, false);
    }
  }

  componentWillUnmount() {
    if(document.body.className.indexOf('aui-modal-open') !== -1) {document.body.className = document.body.className.replace(/\baui-modal-open\b/g, "");}
    window.removeEventListener("resize", this._debouncedUpdate, false);
    window.removeEventListener("keydown", this.handleEsc, false);
  }

  handleEsc = (e) => {
    if (this.props.closeOnEsc && typeof this.props.close === 'function' && e.keyCode === 27) {
      this.props.close();
    }
  }

  renderCloseButton = () => {
    if (this.props.isActive) {
      return (
        <div className="aui-modal-dialog__close" onClick={this.props.close} ref={this.closeRef}>
          <Cancel size="large" className="aui-modal-close-icon-large" />
          <Cancel size="small" className="aui-modal-close-icon-small" />
        </div>
      );
    }
    return null;
  }

  repositionCloseButton = () => {
    const rect = this.bodyRef.current.getBoundingClientRect();
    const right = rect.right - rect.width;
    // Only reposition if the window is positioned center, otherwise respect CSS styling
    if (right > 0) {
      this.closeRef.current.style.right = `${right}px`;
    } else {
      this.closeRef.current.style.right = '';
    }
  }

  render() {
    const {isActive, closeButton, variant, morph} = this.props;
    return(
      <div>
        <div className={classnames("aui-modal", `${this.props.className}`, {"is-active": isActive})} style={this.props.style} tabIndex="-1" role="dialog" aria-hidden={!isActive}>
          <div className={classnames("aui-modal-dialog", [`aui-modal-dialog--${variant}`], {"aui-modal-dialog--morph": morph})}>
            <div ref={this.bodyRef} className="aui-modal-dialog__body">
              <div className="aui-modal-dialog__content">
                {this.props.children}
                {closeButton && variant === 'layer' && this.renderCloseButton()}
              </div>
              {closeButton && variant !== 'layer' && this.renderCloseButton()}
            </div>
          </div>
        </div>
        {isActive && <div className="aui-modal-backdrop"></div>}
      </div>
    );
  }

}

Modal.propTypes = {
  isActive: PropTypes.bool,
  variant: PropTypes.oneOf(['fullpage', 'window', 'layer']),
  morph: PropTypes.bool, // not implemented yet
  closeButton: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};

Modal.defaultProps = {
  variant: 'fullpage',
  style: {},
  className: '',
  closeOnEsc: true
}

export default Modal;
