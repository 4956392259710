import React from 'react';
import cn from 'classnames';
import EntryRow from './EntryRow';
import Pagination from '../../audi-ui-components/Pagination';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';

class EntryTable extends React.Component {

  toggleSort = (field) => {
    let defaultDir = "asc";
    let otherDir = "desc";
    if (field === "createDate") {
      defaultDir = "desc";
      otherDir = "asc";
    }
    if (this.props.sort === `${field} ${defaultDir}`) {
      this.props.onSort(`${field} ${otherDir}`);
    } else {
      this.props.onSort(`${field} ${defaultDir}`);
    }
  }

  render() {
    const {
      data,
      tab,
      sort,
      onClickRow,
      onClickPage,
      page,
      count,
      pageSize,
      isLoading
    } = this.props;
    return (
      <>
        {data && data.length > 0 && <div className="list-header">
          <div className="row mx-n1">
          <div className="col-2 px-1">
            <button className="aui-button sort" onClick={() => { this.toggleSort('createDate'); }}>
              Date
              {sort === 'createDate asc' && <IconUp small />}
              {sort === 'createDate desc' && <IconDown small />}
            </button>
          </div>
          <div className={cn("col-7 px-1", {"col-small-6": tab !== "preorder", "col-small-8": tab === "preorder"})}>Stock</div>
          {tab !== "preorder" && <div className="col-2 d-none d-small-block px-1 text-nowrap text-truncate">Commission #</div>}
          <div className="col-3 col-small-2 px-1">
            <button className="aui-button sort" onClick={() => { this.toggleSort('status'); }}>
              Status
              {sort === 'status asc' && <IconUp small />}
              {sort === 'status desc' && <IconDown small />}
            </button>
          </div>
          </div>
        </div>}

        {data && data.map((entry,i) => <EntryRow key={`row${i}` + (entry.postId || entry.vehicleReservationId)} entry={entry} tab={tab} onClick={onClickRow} />)}

        {onClickPage && (count > pageSize) &&
          <div className="text-center my-3">
            <Pagination
              totalSteps={Math.ceil(count / pageSize)}
              activeIndex={page - 1}
              maxVisibleSteps={6}
              onChange={onClickPage}
            />
          </div>
        }

        {!isLoading && (!data || data.length === 0) && <div className="py-5 mt-5 text-center">We couldn't find any entries</div>}
      </>
    );
  }
}

export default EntryTable;
