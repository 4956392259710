import React from 'react';
import classNames from 'classnames';
import IconSystemHelp from '../audi-ui-components/icons/SystemHelp';

class Tooltip extends React.Component {

  static defaultProps = {
    trigger: <IconSystemHelp small />
  }

  constructor(props) {
    super(props);
    this.state = {
      showTip: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleTriggerClick = () => {
    if (!this.state.showTip) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({showTip: !this.state.showTip});
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleTriggerClick();
  }

  render() {
    return (
      <div className="tooltip" ref={node => { this.node = node; }}>
        <div className="trigger" onClick={this.handleTriggerClick}>{this.props.trigger}</div>
        <div className={classNames('tip', {'show': this.state.showTip})}>{this.props.tip}</div>
      </div>
    );
  }
}

export default Tooltip;
