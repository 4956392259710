import {request} from '../lib/apiRequestWrapper';
import cookie from '../lib/cookie';

import { CLEAR_DATA } from './Data';
import { SET_FILTER_TAB } from './Ui';

const SET_PROFILE_DATA = "USER/SET_PROFILE_DATA";
const UPDATE_PROFILE_DATA = "USER/UPDATE_PROFILE_DATA";
const SET_IS_AUTHED = "USER/SET_IS_AUTHED";
const SET_LOADING = "USER/SET_LOADING";
const SET_UPDATING = "USER/SET_UPDATING";
const SET_ERROR = "USER/SET_ERROR";
const LOGOUT = "USER/LOGOUT";

export function loadProfile(cb, errCb, auth) {
  return (dispatch, getState) => {

    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_ERROR, payload: null});

    return request(
      `${process.env.RAZZLE_API}/1/account/profile`,
      false,
      auth
    ).then((data) => {
      dispatch({ type: SET_PROFILE_DATA, payload: data });
      dispatch({ type: SET_IS_AUTHED, payload: true});
      dispatch({ type: SET_LOADING, payload: false });
      if (data.view && data.view.indexOf("new") === -1) {
        if (data.view.indexOf("used") > -1) {
          dispatch({ type: SET_FILTER_TAB, payload: "used" });
        } else {
          dispatch({ type: SET_FILTER_TAB, payload: "preorder" });
        }
      }
      if (typeof cb === 'function') { cb(data); }
    }).catch((error) => {
      switch (error.status) {
        case 404:
        case 401:
          return dispatch(logout(error));
        default:
          console.error('loadProfile', error);
          dispatch({ type: SET_LOADING, payload: false });
          if (typeof errCb === 'function') { errCb(error); }
      }
    });

  };
}

export function logout(error) {
  return (dispatch, getState) => {
    cookie.removeItem('auth', '/');
    setTimeout(() => {
      dispatch({type: LOGOUT});
      dispatch({type: CLEAR_DATA});
      if (error) {
        dispatch({type: SET_ERROR, payload: error});
      }
    }, 300);
  }
}

export function updateProfile(formData, cb, errorCb) {
  return (dispatch, getState) => {
    dispatch({ type: SET_UPDATING, payload: true });
    let newSettings = {};
    if (formData.settings) {
      let oldSettings = getState().profile.data.settings;
      newSettings = _merge({}, oldSettings, formData.settings);
      formData.settings = JSON.stringify(newSettings);
    }
    return request(
      `${process.env.RAZZLE_API}/1/account/profile`,
      {
        method: "PATCH",
        body: JSON.stringify(formData),
      }
    ).then(() => {
      if (formData.settings) {
        formData.settings = newSettings;
      }
      dispatch({type: UPDATE_PROFILE_DATA, payload: formData});
      if (typeof cb === 'function') { cb(); }
    }).catch((error) => {
      console.error(error);
      dispatch({ type: SET_UPDATING, payload: false });
      if (typeof errorCb === 'function') { errorCb(error); }
    });
  };
}

const defaultState = {
  data: null,
  isAuthed: false,
  isLoading: false,
  isUpdating: false,
  error: null,
}

export function userReducer(state = defaultState, action) {
  switch (action.type) {

    case SET_LOADING:
    return Object.assign({}, state, {
      isLoading: action.payload
    });

    case SET_UPDATING:
    return Object.assign({}, state, {
      isUpdating: action.payload
    });

    case SET_IS_AUTHED:
    return Object.assign({}, state, {
      isAuthed: action.payload
    });

    case SET_PROFILE_DATA:
    return Object.assign({}, state, {
      data: action.payload
    });

    case UPDATE_PROFILE_DATA:
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, action.payload),
      isUpdating: false
    });

    case SET_ERROR:
    return Object.assign({}, state, {
      error: action.payload
    });

    case LOGOUT:
    return defaultState;

    default:
    return state;
  }
}
