import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadProfile } from './redux/User';
import cookie from './lib/cookie';
import { Helmet } from "react-helmet";

import './styles/main.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import Login from './pages/Login';
import ForgotPass from './pages/ForgotPass';
import ResetPass from './pages/ResetPass';
import Group from './pages/Group';
import ListGroups from './pages/ListGroups';
import User from './pages/User';
import ListUsers from './pages/ListUsers';
import ListEntries from './pages/ListEntries';
import ListDealers from './pages/ListDealers';
import Entry from './pages/Entry';
import EntryPreorder from './pages/EntryPreorder';
import ListPayouts from './pages/ListPayouts';
import Payout from './pages/Payout';
import SearchResults from './pages/SearchResults';
import Support from './pages/Support';

const mapStateToProps = state => {
  return {
    isAuthed: state.user.isAuthed,
    isLoading: state.user.isLoading,
    error: state.user.error
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadProfile: (cb, errorCb, auth) => {dispatch(loadProfile(cb, errorCb, auth));},
  }
}

function PrivateRoute({ component, isAuthed, error, ...rest }) {
  let hasCookie = true;
  if (typeof window !== 'undefined') {
    let a = JSON.parse(cookie.getItem('auth'));
    if (a && a.accessToken) {
      hasCookie = true;
    } else {
      hasCookie = false;
    }
  }
  if (!hasCookie || (error && error !== null)) {
    return (<Route {...rest}><Redirect to="/" /></Route>);
  } else if (isAuthed) {
    let Page = component;
    return <Route {...rest} render={routeProps => (
      <div className="page aui-color-gray95">
        <Header history={routeProps.history} />
        <div className="container aui-color-white pb-5">
          <Page {...routeProps} />
        </div>
        <Footer />
      </div>
    )} />
  }
  return (
    <Route {...rest}>
      <LoadingSpinner type="placeholder" />
    </Route>
  );
}

class App extends React.Component {

  componentDidMount() {
    if (!this.props.isAuthed && !this.props.isLoading) {
      var a = JSON.parse(cookie.getItem('auth'));
      if (a && a.accessToken) {
        this.props.loadProfile(null, null, a);
      }
    }
  }

  render() {
    const {isAuthed, error} = this.props;
    return (<>
      <Helmet>
        <style type="text/css">{`
          .page-login{background-image: url("${process.env.PUBLIC_PATH}images/audi-e-tron-gt_mobile.jpg");}
          @media (min-width: 600px) {.page-login{background-image: url("${process.env.PUBLIC_PATH}images/audi-e-tron-gt.jpg");}}
        `}</style>
      </Helmet>
      <Switch>
        <Route exact path="/" render={routeProps => (
          <Login {...routeProps} />
        )} />
        <Route exact path="/forgot-password" render={routeProps => (
          <ForgotPass {...routeProps} />
        )} />
        <Route exact path="/reset" render={routeProps => (
          <ResetPass {...routeProps} />
        )} />
        <Route exact path="/support" render={routeProps => (
          <div className="page aui-color-gray95">
            <Header history={routeProps.history} />
            <div className="container aui-color-white pb-5">
              <Support />
            </div>
            <Footer />
          </div>
        )} />
        <PrivateRoute exact path="/entries/:dealerId?" isAuthed={isAuthed} error={error} component={ListEntries} />
        <PrivateRoute exact path="/entry/:entryId" isAuthed={isAuthed} error={error} component={Entry} />
        <PrivateRoute exact path="/preorder/:entryId" isAuthed={isAuthed} error={error} component={EntryPreorder} />
        <PrivateRoute exact path="/dealers" isAuthed={isAuthed} error={error} component={ListDealers} />
        <PrivateRoute exact path="/profile/:userId?" isAuthed={isAuthed} error={error} component={User} />
        <PrivateRoute exact path="/users" isAuthed={isAuthed} error={error} component={ListUsers} />
        <PrivateRoute exact path="/group/:groupId" isAuthed={isAuthed} error={error} component={Group} />
        <PrivateRoute exact path="/groups" isAuthed={isAuthed} error={error} component={ListGroups} />
        <PrivateRoute exact path="/payouts" isAuthed={isAuthed} error={error} component={ListPayouts} />
        <PrivateRoute exact path="/payout/:payoutId" isAuthed={isAuthed} error={error} component={Payout} />
        <PrivateRoute exact path="/search-results" isAuthed={isAuthed} error={error} component={SearchResults} />
        <Route path="*" render={({ staticContext }) => {
          if (staticContext) { staticContext.status = 404; }
          return <div className="container-fluid py-5 text-center"><p className="aui-headline-5">404 Not Found</p></div>
        }} />
      </Switch>
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
