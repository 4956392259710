import React from 'react';
import classnames from 'classnames';

class Support extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMounted: false
    }
    this.refMojo = React.createRef();
  }

  componentDidMount() {
    this.setState({isMounted: true});
  }

  handleFrameLoad = () => {
    // Load custom css inside iframe
    var mojoWidgetCss = "\
    #content_for_layout, body{background-color: #ffffff;}\
    .section-head {display: none;}\
    .padding-for-content {padding-top: 10px;}\
    label {}\
    .form-control {}\
    .form-footer {}\
    .btn-success {background: #000; border-color: #000; border-radius: 0; padding: 0.8em 2.13em;}\
    .btn-success:hover {background: #4c4c4c; border-color: #4c4c4c;}\
    ";
    this.refMojo.current.contentWindow.postMessage({ css: mojoWidgetCss }, '*');
  }

  render() {
    return (
      <main className="page-support">
        <p className="aui-headline-4 pt-4 mb-2">Audi Dealer Portal Support.</p>
        <p className="mb-5">
          Please submit your request using the form below and a member of the
          Audi Dealer Portal support team will respond as soon as possible.
          Providing a complete description of the issue will ensure the fastest turnaround,
          but please allow a minimum of 2 business days for action.
        </p>
        {this.state.isMounted && <iframe
          ref={this.refMojo}
          onLoad={this.handleFrameLoad}
          src="https://audiau.mojohelpdesk.com/widgets/create_request#/ticket-form/54631"
          className="mojo"
        />}
      </main>
    );
  }
}

export default Support;
