import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ResizeObserver from 'resize-observer-polyfill';
import AudiRingsLogo from '../components/AudiRingsLogo';
import FormField from '../components/FormField';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import { request } from '../lib/apiRequestWrapper';
import { ROLES } from '../constants';
import queryString from 'query-string';

const mobileBreakpoint = 600;

class ResetPass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isSubmitting: false,
      submitStatus: null,
      serverError: false,
      formTheme: null
    }
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(this.layoutUpdate);
    this.resizeObserver.observe(document.body);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(document.body);
  }

  layoutUpdate = (entries, observer) => {
    const {width} = entries[0].contentRect;
    if (width < mobileBreakpoint && this.state.formTheme !== "light") {
      this.setState({formTheme: "light"});
    }
    if (width >= mobileBreakpoint && this.state.formTheme === "light") {
      this.setState({formTheme: null});
    }
  }

  validateForm = (values) => {
    // TODO
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true});
    let queryParams = queryString.parse(this.props.location.search);
    var _values = Object.assign({}, values, {id: queryParams.id, token: queryParams.token});
    request(
      `${process.env.RAZZLE_API}/1/users/password/set`,
      {
        method: 'POST',
        body: JSON.stringify(_values)
      }
    ).then((response) => {
      this.setState({step: 2, submitStatus: 200, isSubmitting: false});
    }).catch((error) => {
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        this.setState({isSubmitting: false});
      } else {
        this.setState({step: 2, submitStatus: error.status, isSubmitting: false});
      }
    });
  }

  render() {
    const {step, formTheme, submitStatus} = this.state;
    return (
      <div className="page-login">

        {this.state.isSubmitting && <LoadingSpinner />}
        <div className="container-fluid">
          <div className="d-flex flex-column justify-content-between justify-content-xsmall-start ps-xsmall-5 position-relative">

            <header className="py-3">
              <div className="text-end"><AudiRingsLogo /></div>
              <p className="aui-headline-4">Dealer sales portal</p>
              <p className="">Reset your password</p>
            </header>

            {step === 1 && <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{}}>
              {(formikBag) => (
                <Form className="pt-3 pb-5">
                  <FormField name="password" type="password" label="New password" formikBag={formikBag} theme={formTheme} wrapperClassName="" />
                  <FormField name="confirmPassword" type="password" label="Confirm password" formikBag={formikBag} theme={formTheme} wrapperClassName="mb-4" />
                  {this.state.serverError && <p className="aui-color-text-red py-3">{this.state.serverError}</p>}
                  <Button isStretched type="submit" variant="primary" theme={formTheme}>Reset password</Button>
                  <Button
                    variant="text"
                    onClick={() => { this.props.history.push("/support") }}
                    className="support"
                    theme={formTheme}
                  >
                    Support
                  </Button>
                </Form>
              )}
            </Formik>}

            {step === 2 && submitStatus === 200 && <div className="pt-3 pb-5">
              <p className={classnames("mb-5", {"aui-color-text-light": formTheme === "light"})}>Your password has been reset.</p>
              <Button variant="primary" onClick={() => { this.props.history.push("/"); }} theme={formTheme}>Login</Button>
              <br />
              <Button
                variant="text"
                onClick={() => { this.props.history.push("/support") }}
                className="support"
                theme={formTheme}
              >
                Support
              </Button>
            </div>}

            {step === 2 && submitStatus !== 200 && <div className="pt-3 pb-5">
              <p className="aui-color-text-red mb-5">The link has either expired or is invalid.</p>
              <Button variant="primary" onClick={() => { this.props.history.push("/forgot-password"); }} theme={formTheme}>Get a new link</Button>
              <br />
              <Button
                variant="text"
                onClick={() => { this.props.history.push("/support") }}
                className="support"
                theme={formTheme}
              >
                Support
              </Button>
            </div>}

          </div>
        </div>
      </div>
    );
  }
}

export default ResetPass;
