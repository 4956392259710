import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import ResizeObserver from 'resize-observer-polyfill';
import AudiRingsLogo from '../components/AudiRingsLogo';
import FormField from '../components/FormField';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import { request } from '../lib/apiRequestWrapper';
import { PERMISSIONS } from '../constants';

import { connect } from 'react-redux';
import { loadProfile } from '../redux/User';
const mapStateToProps = state => {
  return {
    isLoadingProfile: state.user.loading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadProfile: (cb, errCb, auth) => {dispatch(loadProfile(cb, errCb, auth));},
  }
}

const mobileBreakpoint = 600;

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      serverError: false,
      formTheme: null
    }
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(this.layoutUpdate);
    this.resizeObserver.observe(document.body);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(document.body);
  }

  layoutUpdate = (entries, observer) => {
    const {width} = entries[0].contentRect;
    if (width < mobileBreakpoint && this.state.formTheme !== "light") {
      this.setState({formTheme: "light"});
    }
    if (width >= mobileBreakpoint && this.state.formTheme === "light") {
      this.setState({formTheme: null});
    }
  }

  validateForm = (values) => {
    // TODO
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true});
    request(
      `${process.env.RAZZLE_API}/1/account/token`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    ).then((authData) => {
      // authentication success, load profile
      this.props.loadProfile(
        (userData) => {
          if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
            this.props.history.replace("/dealers");
          } else {
            this.props.history.replace("/entries");
          }
        },
        (error) => {
          this.handleServerError(error, formikBag);
        },
        authData
      );
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleServerError = (error, formikBag) => {
    console.log('handleServerError', error);
    let msg = "An error has occured";
    if (error.body && error.body.modelState) {
      formikBag.setErrors(error.body.modelState);
      msg = false;
    } else if (error.body && error.body.message) {
      msg = error.body.message;
    }
    this.setState({isSubmitting: false, serverError: msg});
  }

  render() {
    const {formTheme} = this.state;
    return (
      <div className="page-login">

        {this.state.isSubmitting && <LoadingSpinner />}
        <div className="container-fluid">
          <div className="d-flex flex-column justify-content-between justify-content-xsmall-start ps-xsmall-5 position-relative">

            <header className="py-3">
              <div className="text-end"><AudiRingsLogo /></div>
              <p className="aui-headline-4">Dealer Online Sales Portal</p>
              <p className="aui-headline-1">Always connected</p>
            </header>

            <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{email: "", password: ""}}>
              {(formikBag) => (
                <Form className="pt-3 pb-5 font-extended">
                  <FormField name="email" label="Email" formikBag={formikBag} theme={formTheme} wrapperClassName="" />
                  <FormField name="password" label="Password" type="password" formikBag={formikBag} theme={formTheme} wrapperClassName="" />
                  <p className={classnames("text-end mb-4", {"aui-color-text-light": formTheme === "light"})}>
                    <Link to="/forgot-password" className="forgot-pass-link">Forgot password?</Link>
                  </p>
                  {this.state.serverError && <p className="aui-color-text-red py-3">{this.state.serverError}</p>}
                  <Button isStretched type="submit" variant="primary" theme={formTheme}>Login</Button>
                  <Button
                    variant="text"
                    onClick={() => { this.props.history.push("/support") }}
                    className="support"
                    theme={formTheme}
                  >
                    Support
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
