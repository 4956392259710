export const APPLICATION_CONTAINER_ID = 'AppContainer';

// Form field constants
export const FIELD_CHECKBOX = 'FIELD_CHECKBOX';
export const FIELD_CHECKBOXES = 'FIELD_CHECKBOXES';
export const FIELD_RADIOS = 'FIELD_RADIO';
export const FIELD_SELECT = 'FIELD_SELECT';
export const FIELD_DEALER_SELECT = 'FIELD_DEALER_SELECT';
export const FIELD_DROPDOWN = 'FIELD_DROPDOWN';
export const FIELD_TEXT = 'FIELD_TEXT';
export const FIELD_TEXTAREA = 'FIELD_TEXTAREA';
export const FIELD_SWITCH = 'FIELD_SWITCH';
export const FIELD_DATEPICKER = 'FIELD_DATEPICKER';

export const ROLES = {
  ADMIN: "Admin",
  AUDI_ADMIN: "AudiAdmin",
  REG_MNGR: "RegionalManager",
  GROUP_MNGR: "GroupManager",
  DEALER_ADMIN: "DealerAdmin",
  DEALER: "Dealer"
};

export const ROLES_LIST = [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN, ROLES.DEALER];

export const ROLES_LABELS = {
  [ROLES.ADMIN]: "Admin",
  [ROLES.AUDI_ADMIN]: "Audi Admin",
  [ROLES.REG_MNGR]: "Regional Manager",
  [ROLES.GROUP_MNGR]: "Group Manager",
  [ROLES.DEALER_ADMIN]: "Dealer Admin",
  [ROLES.DEALER]: "Dealer"
};

export const REGIONS = {
  EAST: "Eastern",
  NTH: "Northern",
  STH: "Southern"
};

export const REGIONS_OPTS = [
  {label: "Eastern", value: REGIONS.EAST},
  {label: "Northern", value: REGIONS.NTH},
  {label: "Southern", value: REGIONS.STH}
];

export const PERMISSIONS = {
  LIST_DEALERS: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR],
  LIST_USERS: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN],
  ADD_USER: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN],
  EDIT_USER: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN],
  ASSIGN_ROLE: {
    [ROLES.ADMIN]: [ROLES.ADMIN],
    [ROLES.AUDI_ADMIN]: [ROLES.ADMIN, ROLES.AUDI_ADMIN],
    [ROLES.REG_MNGR]: [ROLES.ADMIN, ROLES.AUDI_ADMIN],
    [ROLES.GROUP_MNGR]: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR],
    [ROLES.DEALER_ADMIN]: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR],
    [ROLES.DEALER]: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN]
  },
  ASSIGN_ANY_LOCATION: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR],
  ASSIGN_VIEW: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN],
  DELETE_USER: [ROLES.ADMIN, ROLES.AUDI_ADMIN, ROLES.REG_MNGR, ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN],
  LIST_GROUPS: [ROLES.ADMIN, ROLES.AUDI_ADMIN],
  ADD_GROUP: [ROLES.ADMIN, ROLES.AUDI_ADMIN],
  EDIT_GROUP: [ROLES.ADMIN, ROLES.AUDI_ADMIN],
  CHANGE_STATUS: [ROLES.GROUP_MNGR, ROLES.DEALER_ADMIN, ROLES.DEALER],
  LIST_PAYOUTS: [ROLES.DEALER_ADMIN, ROLES.DEALER]
};

export const STATUS = {
  PENDING: "Pending",
  PROGRESS: "InProgress",
  CONTACTED: "Contacted",
  SOLD: "Sold",
  RELEASED: "Released",
  CLOSED: "Closed"
};

export const STATUS_LIST = [STATUS.PENDING, STATUS.PROGRESS, STATUS.CONTACTED, STATUS.SOLD, STATUS.RELEASED, STATUS.CLOSED];
export const STATUS_LIST_PREORDER = [STATUS.PENDING, STATUS.PROGRESS, STATUS.CONTACTED, STATUS.SOLD, STATUS.CLOSED];

export const STATUS_LABELS = {
  [STATUS.PENDING]: "Pending action",
  [STATUS.PROGRESS]: "In Progress",
  [STATUS.CONTACTED]: "Contacted",
  [STATUS.SOLD]: "Sold",
  [STATUS.RELEASED]: "Released order",
  [STATUS.CLOSED]: "Closed enquiry"
}

export const TESTIMG = "https://mediaservice.audi.com/media/fast/H4sIAAAAAAAAAFvzloG1tIhBPNonK7EsUS8zXy84tSgzMSezKjEpJ9V63QXONcHX33oyMTBUFDAwMNjVFTFYJufn6iXn5-Wk5hfrFaXmpaQWFacWlWUmpwK15yQCBYEKcvPzoCZ5pOYUpBaphFQWpDJAgRDQtCIGPrCNOYl56XqueaW5yJIFJQwcXgGu7vE-nk4lDMwFeel1hQx1DEwlDDxg4cBQRx_PkMjiIgZBhCGeeSWp6alFQo8WLPne2G7BxMDoycBalphTmgq0TAChzq80Nym1qG3NVFnuKQ-6YT5LgdnA6evp6xofEhngCmRn5iamp-oj2c_l5OjsHe_s7-MfBAw2pminNZ_Ff7BxhDyAmcP86dMnmGLeAE_nkNAg13jHoCDHyGKQKCdQCQ9Mnh0qD_SHMRGBmpeXmlySX6TnkVic4ZyfAgtORiYGVk8GpnJDEGEEIoxBhAmIMK0oaPzJx9d8aN-q2iTbBpn3C6cxNwkBreZONNRNrSjRzUvNzQe5RgzsOok7W-qs6zReFzgGiVx-M38zG5ONmRdIQgpJzeRbcyZkbxQS3rE_RIapdcaBlQrXDdHVXPl_fLPgyx_dtX2HPx479uzMtEeymehqbjIYRnj26vPHz8qQK20OCdfZcF0aXc18v9ZdYsLuyZPYT0fwuM4Waf54dS-6Gu-n4cmh5z98dUv8IicxT2qB0ddKC3Q1uwQfzarfMSXy0FkGpbCLObYvDq9zR1cT2rVGlYutWqtMtKFnh6_Fa4eGR0HoalZLel8Qify66vA50wlvFoqpuZ7cfQhdzdrdxximPixrPhTgdJbFU1rZLaz_PbqaX6rrNS-kFtxpWcNYOWfbpztxVta7wWrgqTAkyNEv2M0_yBeWbJgewlgsejAWTwmMxRFdAGJpw_iMBvDkplEAF2yBGc8S7BnlChNmrYAr2IaapZzy83NSE_POKhQ1XJ3z6x0wS0XBslQBA9wCBgBzOmddRQQAAA.png";
