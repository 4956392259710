import React from 'react';
import classnames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import AudiRingsLogo from './AudiRingsLogo';
import { PERMISSIONS, ROLES } from '../constants';
import TextField from '../audi-ui-components/TextField';
import IconSearch from '../audi-ui-components/icons/Search';
import IconHome from '../audi-ui-components/icons/Home';
import IconGroups from '../audi-ui-components/icons/Groups';
import IconMyDealer from '../audi-ui-components/icons/MyDealer';
import IconDocDollar from '../audi-ui-components/icons/DocumentPricelistDollar';
import IconSystemAdd from '../audi-ui-components/icons/SystemAdd';
import IconSystemHelp from '../audi-ui-components/icons/SystemHelp';
import IconUser from '../audi-ui-components/icons/User';
import IconCancel from '../audi-ui-components/icons/Cancel';
import IconLogin from '../audi-ui-components/icons/Login';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
import { setSearch } from '../redux/Ui';
const mapStateToProps = state => {
  return {
    isAuthed: state.user.isAuthed,
    userData: state.user.data || {},
    search: state.ui.search
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logout: () => {dispatch(logout());},
    setSearch: (s) => {dispatch(setSearch(s));}
  }
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: props.search || "",
      navOpen: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search && this.props.search !== this.state.search) {
      this.setState({search: this.props.search});
    }
  }

  handleClickOutside = (e) => {
    if (
      (this._refNav && !this._refNav.contains(e.target)) &&
      (this._refBurger && !this._refBurger.contains(e.target)) &&
      (this._refSearch && !this._refSearch.contains(e.target))
    ) {
      this.setState({navOpen: false});
    }
  }

  handleClearSearch = () => {
    this.setState({search: ""});
    this.props.setSearch("");
    if (this.props.history.location.pathname === "/search-results") {
      this.props.history.goBack();
    }
  }

  close = () => {
    this.setState({navOpen: false});
  }

  render() {
    const {isAuthed, userData} = this.props;
    const {navOpen} = this.state;
    let homeLink = "/";
    if (userData && userData.role) {
      if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
        homeLink = "/dealers";
      } else {
        homeLink = "/entries";
      }
    }
    let userFamily = "Audi Australia";
    if (userData.role === ROLES.DEALER || userData.role === ROLES.DEALER_ADMIN) {
      userFamily = userData.dealerName;
    } else if (userData.role === ROLES.REG_MNGR) {
      userFamily = userData.region;
    } else if (userData.role === ROLES.GROUP_MNGR) {
      userFamily = userData.groupName;
    } else if (userData.role === ROLES.ADMIN) {
      userFamily = "Admin";
    }
    return (<>
      <header className={classnames("main-header", { open: navOpen })}>
        <div className="container py-3 aui-color-white">
          <div className="row flex-nowrap">
            <div ref={(el) => this._refBurger = el} className="col-auto nav-toggle-wrapper">
              <div className={classnames('main-nav__toggle', { open: navOpen })} onClick={(e) => { this.setState({navOpen: !navOpen}); e.stopPropagation(); }}>
                <span></span><span></span><span></span>
              </div>
            </div>

            <div ref={(el) => this._refSearch = el} className="col-auto search-site-col">
              {isAuthed && <div className="search-site-wrapper d-flex align-items-start">
                <TextField name="searchSite"
                  label="Search"
                  floatingLabel={false}
                  icon={<IconSearch size="small" />}
                  value={this.state.search}
                  onChange={(v) => { this.setState({search:v}); }}
                  onEnter={() => {
                    this.props.setSearch(this.state.search);
                    this.props.history.push("/search-results");
                  }}
                  description={this.state.search ? "Press enter to search" : ` `}
                  className="search-site flex-fill"
                />
                <button className={classnames('clear-search aui-button pt-2 pb-0 px-0', {'visible': this.state.search, 'invisible': !this.state.search})} onClick={this.handleClearSearch}>
                  <IconCancel small />
                </button>
              </div>}
            </div>

            <div className="col text-end">
              <Link className="home-link" to={homeLink}><AudiRingsLogo /></Link>
            </div>
          </div>

          <div className={classnames('main-nav-wrapper', {open: navOpen})}>
            <nav ref={(el) => this._refNav = el} className={classnames('main-nav__panel', {open: navOpen})}>

              {isAuthed && <ul>
                <li><NavLink to={homeLink} onClick={this.close}><IconHome small /> Home</NavLink></li>
                {PERMISSIONS.LIST_PAYOUTS.indexOf(userData.role) > -1 &&
                  <li><NavLink to="/payouts" onClick={this.close}><IconDocDollar small /> Payouts</NavLink></li>
                }
                {PERMISSIONS.LIST_USERS.indexOf(userData.role) > -1 &&
                  <li><NavLink to="/users" onClick={this.close}><IconGroups small /> Users</NavLink></li>
                }
                {PERMISSIONS.ADD_USER.indexOf(userData.role) > -1 &&
                  <li><NavLink to="/profile/new" onClick={this.close}><IconSystemAdd small /> Add new user</NavLink></li>
                }
                {PERMISSIONS.LIST_GROUPS.indexOf(userData.role) > -1 &&
                  <li><NavLink to="/groups" onClick={this.close}><IconMyDealer small /> Dealer Groups</NavLink></li>
                }
                {PERMISSIONS.ADD_GROUP.indexOf(userData.role) > -1 &&
                  <li><NavLink to="/group/new" onClick={this.close}><IconSystemAdd small /> Add new group</NavLink></li>
                }
                <li><NavLink to="/support" onClick={this.close}><IconSystemHelp small /> Support</NavLink></li>
                <li className="divider"></li>
                <li><NavLink to="/profile" onClick={this.close}><IconUser small /> Profile: {userData.firstName} {userData.lastName}</NavLink></li>
                <li><a onClick={this.props.logout}>Logout</a></li>
              </ul>}

              {!isAuthed && <ul>
                <li><Link to="/"><IconLogin small /> Login</Link></li>
              </ul>}
            </nav>
            <div className={classnames('main-nav__overlay', {open: navOpen})}></div>
          </div>
        </div>
      </header>
      {isAuthed && <div className="container aui-color-white pb-3">
        <p className="welcome aui-headline-4">Welcome <b>{userData.firstName} {userData.lastName}</b></p>
        <p className="user-family">{userFamily}</p>
      </div>}
    </>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
