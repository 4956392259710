import React from 'react';
import classnames from 'classnames';
import Button from '../../audi-ui-components/Button';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';
import { ROLES, ROLES_LABELS } from '../../constants';

class UsersTable extends React.Component {

  toggleSort = (field) => {
    if (this.props.sort === `${field} asc`) {
      this.props.onSort(`${field} desc`);
    } else {
      this.props.onSort(`${field} asc`);
    }
  }

  render() {
    const {
      data,
      sort,
      onClickRow,
      isLoading
    } = this.props;
    return (
      <>
        {data && data.length > 0 && <div className="list-header">
          <div className="row mx-n1">
          <div className="col-6 px-1">
            <button className="aui-button sort" onClick={() => { this.toggleSort('lastName'); }}>
              User
              {sort === 'lastName asc' && <IconUp small />}
              {sort === 'lastName desc' && <IconDown small />}
            </button>
          </div>
          <div className="col px-1">Location</div>
          </div>
        </div>}

        {data && data.map(u => {
          return (
            <div className="row mx-n1 list-item" key={u.userId}>
              <div className="col-6 px-1">
                <div className="pe-3">
                  <b>{u.firstName} {u.lastName}</b><br />
                  <div className="text-nowrap text-truncate">{u.email}</div>
                  Role: {ROLES_LABELS[u.role]}
                </div>
              </div>
              <div className="col-3 col-small-4 px-1">
                {(u.role === ROLES.DEALER || u.role === ROLES.DEALER_ADMIN) && u.dealerName}
                {u.role === ROLES.REG_MNGR && u.region}
                {u.role === ROLES.GROUP_MNGR && u.groupName}
              </div>
              <div className="col-3 col-small-2 px-1 text-end">
                <Button variant="secondary" onClick={() => { onClickRow(u.userId); }}>View</Button>
              </div>
            </div>
          );
        })}

        {!isLoading && (!data || data.length === 0) && <div className="py-5 mt-5 text-center">We couldn't find any users</div>}

      </>
    );
  }
}

export default UsersTable;
