import React from 'react';
import classnames from 'classnames';
import Tooltip from '../../components/Tooltip';
import Button from '../../audi-ui-components/Button';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';

class DealersTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sticky: false
    }
    this.refTop = React.createRef();
  }

  componentDidMount() {
    this.topObserver = new IntersectionObserver((entries) => {
      this.setState({sticky: !entries[0]['isIntersecting']});
    }, { threshold: [0, 1] });
    this.topObserver.observe(this.refTop.current);
  }

  componentWillUnmount() {
    this.topObserver.unobserve(this.refTop.current);
  }

  toggleSort = (field) => {
    if (this.props.sort === `${field} asc`) {
      this.props.onSort(`${field} desc`);
    } else {
      this.props.onSort(`${field} asc`);
    }
  }

  render() {
    const {
      data,
      sort,
      onClickRow,
      isLoading
    } = this.props;
    return (
      <>
        <div ref={this.refTop} />

        {data && data.length > 0 && <div className={classnames("list-header has-tooltips", {"sticky": this.state.sticky})}>
          <div className="row mx-n1">
          <div className="col-4 px-1">
            <button className="aui-button sort" onClick={() => { this.toggleSort('name'); }}>
              Dealer
              {sort === 'name asc' && <IconUp small />}
              {sort === 'name desc' && <IconDown small />}
            </button>
          </div>
          <div className="col px-1">
            <div className="row mx-n1">

              <div className="col-4 px-1 position-relative">
                <Tooltip tip={<div className="px-3 py-2 px-medium-4 py-medium-3"><p><b>Enquiries</b></p><p>Pending / Total</p></div>} />
                <div className="text-nowrap text-truncate">Enquiries</div>
              </div>

              <div className="col-4 px-1 position-relative">
                <Tooltip tip={<div className="px-3 py-2 px-medium-4 py-medium-3"><p><b>Orders</b></p><p>Pending / Total</p></div>} />
                <div className="text-nowrap text-truncate">Orders</div>
              </div>

              <div className="col-4 px-1 position-relative">
                <Tooltip tip={<div className="px-3 py-2 px-medium-4 py-medium-3"><p><b>Sales</b></p><p>Sales / Total Orders</p></div>} />
                <div className="text-nowrap text-truncate">Sales</div>
              </div>

            </div>
          </div>
          <div className="col-3 col-small-2 px-1" />
          </div>
        </div>}

        {data && data.map(d => {
          let isTotal = d.name === "Total";
          return (
            <div className={classnames("row mx-n1 list-item", {"dealers-total": isTotal})} key={d.dealerId}>
              <div className="col-4 px-1 align-self-center"><b>{d.name}</b></div>
              <div className="col px-1 align-self-center"><b className={classnames({"aui-color-text-red": d.pendingEnquires > 0})}>{d.pendingEnquires || 0}</b> / {d.totalEnquires || 0}</div>
              <div className="col px-1 align-self-center"><b className={classnames({"aui-color-text-red": d.pendingReservation > 0})}>{d.pendingReservation || 0}</b> / {d.totalReservations || 0}</div>
              <div className="col px-1 align-self-center"><b className="">{d.totalSales}</b> / {d.totalReservations}</div>
              <div className="col-3 col-small-2 text-end px-1 align-self-center">
                {!isTotal && <Button variant="secondary" onClick={() => { onClickRow(d.dealerId); }}>View</Button>}
              </div>
            </div>
          );
        })}

        {!isLoading && (!data || data.length === 0) && <div className="py-5 mt-5 text-center">We couldn't find any dealers</div>}

      </>
    );
  }
}

export default DealersTable;
