import React from 'react';
import TextField from '../audi-ui-components/TextField';
import IconCalendar from '../audi-ui-components/icons/CalendarEvents';
import DatePicker from 'react-datepicker';

class DateFieldInput extends React.Component {
  handleChange = (v,e) => {
    this.props.onChange(e);
  }
  render() {
    return (
      <TextField
        {...this.props}
        icon={<IconCalendar size="small" />}
        onChange={this.handleChange}
      />
    );
  }
}

class DateField extends React.Component {

  render() {
    var d = this.props.value;
    if (!(d instanceof Date && !isNaN(d))) {
      d = "";
    }
    return (
      <DatePicker
        selected={d}
        dateFormat={this.props.dateFormat}
        showYearDropdown
        onChange={this.props.onChange}
        popperPlacement={this.props.popperPlacement}
        customInput={<DateFieldInput {...this.props} />}
      />
    );
  }
}

DateField.defaultProps = {
  dateFormat: "dd/MM/yyyy"
}

export default DateField;
