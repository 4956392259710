import React from 'react';

// convert number to currency string with comma
// returns JSX or string
export const formatDollar = (num, returnJsx) => {
  if (typeof num === 'undefined' || num === null || num === "") { return " "; }
  if (typeof num === 'string' && num.indexOf('$') === 0) { return num; }
  var _num = parseFloat(num.replace(",",""));
  var p = _num.toFixed(2).split(".");
  var s = _num < 0 ? "-$" : "$";
  var n = p[0].split("").reverse().reduce(function(acc, _num, i, orig) {
      return  _num === "-" ? acc : _num + (i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
  if (returnJsx) {
    return (<span className="currency"><sup>{s}</sup>{n}</span>);
  }
  return s+n;
};
