import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '../audi-ui-components/Button';
import IconCancel from '../audi-ui-components/icons/Cancel';
import IconCaution from '../audi-ui-components/icons/Caution';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import { STATUS, STATUS_LABELS, FIELD_RADIOS, ROLES, PERMISSIONS } from '../constants';
import { request } from '../lib/apiRequestWrapper';
import { formatDollar } from '../lib/helpers';

import { connect } from 'react-redux';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    currentDealerId: state.ui.currentDealerId
  }
}
const mapDispatchToProps = dispatch => {
  return {
  }
}

class Entry extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      entry: {},
      rbData: null,
      isLoadingEntry: true,
      fetchError: null,
      isSubmitting: false,
      serverError: null,
      updateSuccess: false
    }
  }

  componentDidMount() {
    const {match, userData} = this.props;
    if (match.params.entryId) {
      request(
        `${process.env.RAZZLE_API}/1/posts/${match.params.entryId}`
      ).then((data) => {
        this.setState({entry: data, isLoadingEntry: false});
        // update status
        if (data.status === STATUS.PENDING && (PERMISSIONS.CHANGE_STATUS.indexOf(userData.role) > -1)) {
          request(
            `${process.env.RAZZLE_API}/1/posts/${match.params.entryId}`,
            {
              method: "PATCH",
              body: JSON.stringify({status: STATUS.PROGRESS}),
            }
          ).then(() => {
            data.status = STATUS.PROGRESS;
            this.setState({entry: data});
          }).catch((error) => {
            console.error(error);
          });
        }
        // get Redbook estimate
        if (data.tradeVehicle && data.tradeVehicle.redbookCode) {
          request(
            `${process.env.RAZZLE_MYAUDI_API}/1/services/vehicle/code/${data.tradeVehicle.redbookCode}`,
            {method: 'POST'}
          ).then((car) => {
            // console.log(car);
            this.setState({rbData: car});
          }).catch((error) => {
            console.error(error);
          });
        }
      }).catch((error) => {
        console.error(error);
        this.setState({isLoadingEntry: false, fetchError: error.status});
      });
    }
  }

  handleClose = () => {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1 && this.props.currentDealerId) {
      this.props.history.push(`/entries/${this.props.currentDealerId}`);
    } else {
      this.props.history.push("/entries");
    }
  }

  submitForm = (values, formikBag) => {
    const {match} = this.props;
    const {entry} = this.state;
    this.setState({isSubmitting: true, serverError: null, updateSuccess: false});
    request(
      `${process.env.RAZZLE_API}/1/posts/${match.params.entryId}`,
      {
        method: "PATCH",
        body: JSON.stringify({status: values.status}),
      }
    ).then(() => {
      let newEntry = Object.assign({}, entry, {status: values.status});
      this.setState({entry: newEntry, updateSuccess: true, isSubmitting: false});
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    });
  }

  render() {
    const {userData} = this.props;
    const {entry, isLoadingEntry, fetchError, rbData} = this.state;
    let img = entry.imageUrl ? entry.imageUrl : "";
    if (img) {
      let i = img.indexOf("?");
      if (i === -1) {
        img = img + "?wid=900";
      } else {
        let qs = queryString.parse(img.substring(i));
        qs.wid = 200;
        img = img.substring(0,i) + "?" + queryString.stringify(qs);
      }
    }
    return (
      <main className="page-entry">
        {isLoadingEntry && <LoadingSpinner />}

        {!fetchError && <div className="row">

          <div className="col-12 col-medium-9">
            <p><b>&nbsp;</b></p>
            <hr className="mb-3 d-none d-medium-block" />

            <div className="row entry-vehicle">
              <div className="col col-medium-7">
                <div className="mb-3">
                  <p className="aui-headline-4">{entry.modelYear} {entry.model} {entry.usedCar ? "(pre-owned)" : ""}</p>
                  <p>{entry.engine}</p>
                  {entry.requestType === "Enquiry" && entry.isReserved &&
                    <p className="reserved-text"><span className="reserved-dot" /> This car is reserved</p>
                  }
                </div>
                <p>{entry.usedCar ? "Display Price" : "Manufacturer’s Recommended Drive Away Price"} <b>${entry.dealerListPrice}</b></p>
                {entry.usedMileage && <p>Mileage <b>{entry.usedMileage}</b></p>}
                <p>Stock ID <b>{entry.stockNumber}</b></p>
              </div>
              <div className="col-auto order-medium-last">
                <button onClick={this.handleClose} className="plain-button detail-close">
                  <span className="svg-wrapper"><IconCancel large /></span><br />close
                </button>
              </div>
              <div className="col-12 col-medium">
                <div className="vehicle-img" style={{backgroundImage: `url("${img}")`}} />
              </div>
            </div>

            <div className="entry-details">
              <hr className="my-3 d-none d-medium-block" />
              <p className="mt-3 mt-medium-none">
                {entry.requestType === "Reserve" && <b className="aui-color-text-red">Order</b>}
                {entry.requestType === "Enquiry" && <b>Enquiry</b>}
              </p>
              <hr className="my-3 d-medium-none" />
              <p>Customer: {entry.customer && `${entry.customer.firstName} ${entry.customer.lastName}`}</p>
              <p>Email: {entry.customer && entry.customer.email}</p>
              <p>Phone: {entry.customer && entry.customer.mobile}</p>
              <p>Postcode: {entry.customer && entry.customer.postcode}</p>

              {entry.tradeVehicle && <>
                <hr className="my-3" />
                <p className="aui-headline-5 mb-2">Trade-in</p>
                <p>Model: {`${entry.tradeVehicle.year} ${entry.tradeVehicle.make} ${entry.tradeVehicle.model}`}</p>
                <p>Registration no: {entry.tradeVehicle.regoNumber}</p>
                <p>Registration state: {entry.tradeVehicle.regoState}</p>
                <p>Kms: {entry.tradeVehicle.kilometers}</p>
                <p>Financed: {(entry.tradeVehicle.hasFinance === "True" || entry.tradeVehicle.hasFinance === "true" || entry.tradeVehicle.hasFinance === true) ? "Yes" : "No"}</p>
                {rbData && <div className="p-3 mt-2 aui-color-gray95">
                  <p className="mb-2">Trade-in estimate from RedBook:</p>
                  <p><b>{rbData.make} {rbData.family} {rbData.year}</b></p>
                  <div className="rb-estimate">
                      ${rbData.tradeinmin}
                      <span className="rb-estimate-arrow" />
                      ${rbData.tradeinmax}
                  </div>
                </div>}
              </>}

              {entry.requestType && entry.requestType === "Reserve" && <>
                <hr className="my-3" />
                <p className="aui-headline-5 mb-2">Desired figures</p>
                <p>Driveaway price: {entry.pricingRequirement && formatDollar(entry.pricingRequirement.driveAwayPrice)}</p>
                {entry.pricingRequirement.changeOverPrice &&
                  <p>Changeover figure: {entry.pricingRequirement && formatDollar(entry.pricingRequirement.changeOverPrice)}</p>
                }
                <p>Monthly repayment: {entry.pricingRequirement && formatDollar(entry.pricingRequirement.monthlyRepayment)}</p>
                <hr className="my-3" />
                <p>Message:</p>
                <div>{entry.pricingRequirement && entry.pricingRequirement.otherRequests}</div>
                <hr className="my-3" />
                <p>Order no: <a className="aui-textlink" href={entry.invoiceUrl} target="_blank" rel="noopener noreferrer">{entry.receiptNumber}</a></p>
              </>}

            </div>

          </div>

          <div className="col-12 col-medium-3 ps-medium-5">
            <p className="d-none d-medium-block"><b>Status</b></p>
            <hr className="my-3 mt-medium-0" />
            <p className="mb-5">
              <b>
                <span className="d-medium-none">Status: </span>
                <span className={classnames({
                  "aui-color-text-red": entry.status === STATUS.PENDING,
                  "aui-color-text-green": entry.status === STATUS.PROGRESS
                })}>{STATUS_LABELS[entry.status]}</span>
              </b>
            </p>
            {PERMISSIONS.CHANGE_STATUS.indexOf(userData.role) > -1 && entry.status === STATUS.PROGRESS && <Formik onSubmit={this.submitForm} initialValues={{}}>
              {(formikBag) => (
                <Form>
                  <FormField name="status"
                    label="Change status?"
                    fieldType={FIELD_RADIOS}
                    options={
                      entry.requestType === "Reserve" ?
                      [{label: STATUS_LABELS[STATUS.RELEASED], value: STATUS.RELEASED}, {label: STATUS_LABELS[STATUS.SOLD], value: STATUS.SOLD}] :
                      [{label: STATUS_LABELS[STATUS.CONTACTED], value: STATUS.CONTACTED}, {label: STATUS_LABELS[STATUS.CLOSED], value: STATUS.CLOSED}]
                    }
                    formikBag={formikBag}
                  />
                  <p className="aui-color-text-red">Please note, status can only be updated once.</p>
                  <p className="my-3">
                    <Button type="submit" variant="primary" disabled={!formikBag.dirty}>Submit</Button>
                  </p>
                </Form>
              )}
            </Formik>}
            {this.state.updateSuccess && <div className="my-5">
              <p className="aui-color-text-green mb-1">The status has been updated</p>
              {entry.status === STATUS.SOLD && <p className=""><b><IconCaution small style={{verticalAlign: "text-bottom"}} /> Please update status to sold in IDS.</b></p>}
            </div>}
          </div>

        </div>}

        {fetchError && <div className="py-5">
          {fetchError === 401 || fetchError === 404 && <>
            <p className="mb-3 aui-color-text-red">This entry doesn't exist, or you don't have permission to view it.</p>
            <p className="aui-color-text-red">Please do not enter random numbers into the addres bar. Thank you.</p>
          </>}
          {fetchError !== 401 && fetchError !== 404 &&
            <p className="aui-color-text-red">There was an error fetching the data. Please try reloading the page.</p>
          }
        </div>}

      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
