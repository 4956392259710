import React from 'react';
import classnames from 'classnames';
import HomeLink from '../../components/HomeLink';
import LoadingSpinner from '../../components/LoadingSpinner';
import Pagination from '../../audi-ui-components/Pagination';
import Dropdown from '../../audi-ui-components/Dropdown';
import DateField from '../../components/DateField';
import PayoutRow from './PayoutRow';
import IconUp from '../../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../../audi-ui-components/icons/ArrowStraightDown';
import { ROLES, PERMISSIONS } from '../../constants';
import _debounce from 'lodash/debounce';

import { connect } from 'react-redux';
import { getPayouts } from '../../redux/Data';
import { setPayoutsFilters, setPagePayouts } from '../../redux/Ui';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    payouts: state.data.payouts,
    payoutsCount: state.data.payoutsCount,
    pageSize: state.ui.pageSize,
    pagePayouts: state.ui.pagePayouts,
    isLoadingPayouts: state.data.isLoadingPayouts,
    filterPayoutsDateStart: state.ui.filterPayoutsDateStart,
    filterPayoutsDateEnd: state.ui.filterPayoutsDateEnd
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getPayouts: () => {dispatch(getPayouts());},
    setPayoutsFilters: (type, v) => {dispatch(setPayoutsFilters(type, v));},
    setPagePayouts: (p) => {dispatch(setPagePayouts(p));},
  }
}

class ListPayouts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileFiltersOpen: false,
      activeMobileFilter: null,
    }
    this._debouncedFetch = _debounce(props.getPayouts, 1000);
  }

  componentDidMount() {
    this.props.getPayouts();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pagePayouts !== prevProps.pagePayouts ||
      this.props.filterPayoutsDateStart !== prevProps.filterPayoutsDateStart ||
      this.props.filterPayoutsDateEnd !== prevProps.filterPayoutsDateEnd
    ) {
      this._debouncedFetch();
    }
  }

  toggleMobileFilter = (n) => {
    if (this.state.mobileFiltersOpen && n === this.state.activeMobileFilter) {
      this.setState({
        activeMobileFilter: null,
        mobileFiltersOpen: false
      });
    } else {
      this.setState({
        activeMobileFilter: n,
        mobileFiltersOpen: true
      });
    }
  }

  handleClick = (id) => {
    this.props.history.push(`/payout/${id}`);
  }

  handlePaginationChange = (idx) => {
    window.scrollTo(0, 0);
    this.props.setPagePayouts(idx + 1);
  }

  render() {
    const {userData, payouts} = this.props;
    const {activeMobileFilter} = this.state;
    if (PERMISSIONS.LIST_PAYOUTS.indexOf(userData.role) === -1) {
      return (<div>You do not have permission to view this page.</div>);
    }
    return (
      <main className="page-payouts">
        {this.props.isLoadingPayouts &&
          <LoadingSpinner type={!payouts || payouts.length < 1 ? 'overlay' : 'fixed'}>Loading</LoadingSpinner>
        }

        <div className="row">

          <div className="filters col-12 col-medium-3 ps-medium-4 order-medium-last">
            <div className="d-medium-none mobile-filter-links">
              <button onClick={() => { this.toggleMobileFilter("filter"); }} className={classnames({"active": activeMobileFilter === "filter"})}>Filter</button>
            </div>

            <div className={classnames("filter", {"open": activeMobileFilter === "filter"})}>
              <div className="pt-4 pb-2 d-none d-medium-block">Filter</div>
              <div className="filter-inner">
                <DateField name="startDate"
                  label="Start date"
                  value={this.props.filterPayoutsDateStart}
                  popperPlacement="left"
                  onChange={(v) => { this.props.setPayoutsFilters("start", v); }}
                />
                <DateField name="endDate"
                  label="End date"
                  value={this.props.filterPayoutsDateEnd}
                  popperPlacement="left"
                  onChange={(v) => { this.props.setPayoutsFilters("end", v); }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-medium-9">

            <div className="list-header d-none d-small-block">
              <div className="row mx-n1">
              <div className="col-9 col-small-10 px-1">
                <div className="row mx-n1">
                  <div className="col-12 col-small-4 px-1">
                    Amount
                  </div>
                  <div className="col-12 col-small-4 px-1">
                    Account
                  </div>
                  <div className="col-12 col-small-2 px-1">
                    Date
                  </div>
                  <div className="col-12 col-small-2 px-1">
                    <div className="ps-small-3 text-nowrap text-truncate">Est. arrival</div>
                  </div>
                </div>
              </div>
              </div>
            </div>

            {payouts && payouts.map(row => <PayoutRow row={row} onClick={this.handleClick} key={row.id} />)}

            {this.props.payoutsCount > this.props.pageSize &&
              <div className="text-center my-3">
                <Pagination
                  totalSteps={Math.ceil(this.props.payoutsCount / this.props.pageSize)}
                  activeIndex={this.props.pagePayouts - 1}
                  maxVisibleSteps={6}
                  onChange={this.handlePaginationChange}
                />
              </div>
            }

            {!this.props.isLoadingPayouts && (!payouts || payouts.length === 0) && <div className="py-5 mt-5 text-center">We couldn't find any payouts</div>}
          </div>

        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPayouts);
