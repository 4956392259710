import queryString from 'query-string';
import cookie from './cookie';

function handleResponse(response) {
  let ok = response.status >= 200 && response.status < 300;
  let contentType = response.headers.get('Content-Type') || '';

  // json response
  if (contentType.includes('application/json')) {
    if (ok) {
      return response.json().catch(() => response);
    }
    // error
    return response.json().then((body) => {
      return Promise.reject({
        status: response.status,
        body,
      });
    });
  }

  // not json response
  if (ok) {
    return response;
  }
  return Promise.reject({
    status: response.status,
    body: response.body
  });
}

export const request = (base, options, auth) => {

  // Request config
  let config = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
  };
  if (options) {
    config = Object.assign({}, config, options);
  }
  if (auth && auth.accessToken) {
    config.headers.Authorization = `bearer ${auth.accessToken}`;
  } else {
    if (typeof window !== 'undefined' && cookie.getItem('auth')) {
      auth = JSON.parse(cookie.getItem('auth'));
      if (auth.accessToken) {
        config.headers.Authorization = `bearer ${auth.accessToken}`;
      }
    }
  }

  // Request params
  let uri = `${base}`;
  if (options && options.path) {
    uri = `${base}/${options.path}`;
  }
  if (options && options.params) {
    uri = `${uri}?${queryString.stringify(options.params)}`;
  }

  return fetch(uri, config).then((response) => {

    if (response.status === 401 && auth && !uri.includes('logout')) {
      console.log('unauthorised, refresh token and retry');
      return fetch(
        `${process.env.RAZZLE_API}/1/account/token/refresh`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': `bearer ${auth.accessToken}`
          },
          body: JSON.stringify({
            clientId: auth.clientId,
            refreshToken: auth.refreshToken,
          })
        }
      ).then((refreshRes) => {
        return refreshRes.json().then(updatedAuth => {
          if (updatedAuth.accessToken) {
            updatedAuth.clientId = auth.clientId;
            cookie.setItem('auth', JSON.stringify(updatedAuth), '', '/');
            config.headers.Authorization = `bearer ${updatedAuth.accessToken}`;
            return fetch(uri, config).then((responseTwo) => {
              return handleResponse(responseTwo);
            });
          } else {
            return handleResponse(response);
          }
        })
      });
    } else {
      if (auth && auth.accessToken) {
        cookie.setItem('auth', JSON.stringify(auth), '', '/');
      }
      return handleResponse(response);
    }

  });

};
