import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from '../components/LoadingSpinner';
import Dropdown from '../audi-ui-components/Dropdown';
import Button from '../audi-ui-components/Button';
import IconUp from '../audi-ui-components/icons/ArrowStraightUp';
import IconDown from '../audi-ui-components/icons/ArrowStraightDown';
import { ROLES, PERMISSIONS } from '../constants';

import { connect } from 'react-redux';
import { getGroups } from '../redux/Data';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
    groups: state.data.groups,
    isLoadingGroups: state.data.isLoadingGroups
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getGroups: () => {dispatch(getGroups());},
  }
}

class ListGroups extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.getGroups();
  }

  handleClick = (id) => {
    this.props.history.push(`/group/${id}`);
  }

  render() {
    const {userData, groups} = this.props;
    const {activeMobileFilter} = this.state;
    if (PERMISSIONS.LIST_GROUPS.indexOf(userData.role) === -1) {
      return (<div>You do not have permission to view this page.</div>);
    }
    return (
      <main className="page-groups">
        {this.props.isLoadingGroups &&
          <LoadingSpinner type={!groups || groups.length < 1 ? 'overlay' : 'fixed'}>Loading</LoadingSpinner>
        }

        <div className="row">
          <div className="col-12 col-medium-9">

            <div className="list-header">
              <div className="row mx-n1">
                <div className="col px-1">Group</div>
              </div>
            </div>

            {groups && groups.map(g => {
              return (
                <div className="row mx-n1 list-item" key={g.groupId}>
                  <div className="col-9 col-small-10 px-1">
                    <div className="pe-3">
                      <b>{g.name}</b>
                    </div>
                  </div>
                  <div className="col-3 col-small-2 px-1 text-end">
                    <Button variant="secondary" onClick={() => { this.handleClick(g.groupId); }}>View</Button>
                  </div>
                </div>
              );
            })}

            {!this.props.isLoadingGroups && (!groups || groups.length === 0) && <div className="py-5 mt-5 text-center">We couldn't find any groups</div>}

          </div>
          <div className="col-medium-3 d-none d-medium-block ps-4">
          </div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListGroups);
