import React from 'react';
import classnames from 'classnames';
import Button from '../audi-ui-components/Button';
import { request } from '../lib/apiRequestWrapper';

class ExportEntries extends React.Component {

  exportCsv = () => {
    const {dealerId, filterEntriesStatus, filterEntriesDateStart, filterEntriesDateEnd, sortEntries} = this.props;

    let q = `?$orderby=${sortEntries}`;

    let filters = [];
    if (dealerId) {
      filters.push(`dealerId eq ${dealerId}`);
    }
    if (filterEntriesStatus && filterEntriesStatus.length) {
      let s = filterEntriesStatus.join(' or ');
      filters.push(`(${s})`);
    }
    if (filterEntriesDateStart) {
      let ds = moment(filterEntriesDateStart).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') ge ${ds}`);
    }
    if (filterEntriesDateEnd) {
      let de = moment(filterEntriesDateEnd).toISOString();
      filters.push(`cast(createDate, 'Edm.DateTimeOffset') le ${de}`);
    }
    if (filters.length) {
      let f = filters.join(' and ');
      q = q.concat(`&$filter=${f}`);
    }
    request(
      `${process.env.RAZZLE_API}/1/posts/export${q}`
    ).then((res) => {
      res.text().then(csvString => {
        // console.log(csvString);
        var pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
        pom.setAttribute('download', "export.csv");
        if (document.createEvent) {
          var e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          pom.dispatchEvent(e);
        }
        else {
          pom.click();
        }
      });
    }).catch((error) => {
      console.error(error);
      // let msg = "An error has occured";
      // if (error.body && error.body.message) {
      //   msg = error.body.message;
      // }
      // this.setState({isLoading: false, serverError: msg});
    });
  }

  render() {
    return (
      <div className={classnames("filter", {"open": this.props.isOpen})}>
        <div className="pt-4 pb-2 d-none d-medium-block">Export</div>
        <div className="filter-inner">
          <Button variant="primary" onClick={this.exportCsv}>Export to CSV</Button>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ExportEntries;
