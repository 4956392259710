import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../audi-ui-components/Checkbox';
import Fieldset from '../audi-ui-components/Fieldset';
import _pull from 'lodash/pull';

class CheckboxFieldset extends React.Component {

  handleChange = (v) => {
    const {value, onChange} = this.props;
    let newValue = value || [];
    if (newValue.indexOf(v) === -1) {
      newValue.push(v);
    } else {
      _pull(newValue, v);
    }
    onChange(newValue, event);
  }

  render() {
    const {name, options, value, onChange, label, ...rest} = this.props;
    return (
      <Fieldset title={label} {...rest} v={value.length}
        fields={options.map(
          opt => <Checkbox name={name}
            label={opt.label}
            value={opt.value}
            checked={value.indexOf(opt.value) !== -1}
            disabled={opt.disabled}
            onChange={this.handleChange} />
        )}
      />
    );
  }

}

CheckboxFieldset.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_fieldset-themes.scss
};

export default CheckboxFieldset;
