import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from '../components/LoadingSpinner';
import IconCancel from '../audi-ui-components/icons/Cancel';
import { PERMISSIONS } from '../constants';
import { request } from '../lib/apiRequestWrapper';

import { connect } from 'react-redux';
const mapStateToProps = state => {
  return {
    userData: state.user.data || {},
  }
}

class Payout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      payoutData: []
    }
  }

  componentDidMount() {
    const {userData, match} = this.props;
    // fetch data
    request(
      `${process.env.RAZZLE_API}/1/stripe/payout/${match.params.payoutId}/transactions`
    ).then((data) => {
      // console.log(data);
      this.setState({isLoading: false, payoutData: data});
    }).catch((error) => {
      console.error(error);
      let msg = "An error has occured";
      if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isLoading: false, serverError: msg});
    });
  }

  handleClose = () => {
    const {userData} = this.props;
    if (PERMISSIONS.LIST_PAYOUTS.indexOf(userData.role) > -1) {
      this.props.history.push("/payouts");
    } else {
      if (PERMISSIONS.LIST_DEALERS.indexOf(userData.role) > -1) {
        this.props.history.push("/dealers");
      } else {
        this.props.history.push("/entries");
      }
    }
  }

  render() {
    const {userData} = this.props;
    const {payoutData} = this.state;
    return (
      <main className="page-payout">
        {(this.state.isLoading || this.state.isSubmitting) &&
          <LoadingSpinner />
        }

        <div className="row">
          <div className="col-12 col-medium-9">

            <div className="text-end mb-2">
              <button onClick={this.handleClose} className="plain-button detail-close">
                <span className="svg-wrapper"><IconCancel large /></span><br />close
              </button>
            </div>

            {payoutData && payoutData.map(row => {
              return (
                <div key={row.id} className="payout-detail__row py-3">
                  <p>Amount: {row.amount}</p>
                  <p>Date: {row.createDate}</p>
                  <p>Commission #: {row.commissionNumber}</p>
                  <p>Reservation #: {row.reservationNumber}</p>
                  <p>Customer: {row.customerDetails}</p>
                  <p>Source: {row.source}</p>
                  <p>Status: {row.status}</p>
                </div>
              );
            })}

          </div>
        </div>
      </main>
    );
  }
}

export default connect(mapStateToProps)(Payout);
