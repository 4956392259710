import React from 'react';
import Button from '../../audi-ui-components/Button';

class PayoutRow extends React.Component {
  render() {
    const {row} = this.props;
    return (
      <div className="row mx-n1 list-item">
        <div className="col-9 col-small-10 px-1">
          <div className="row mx-n1">
            <div className="col-12 col-small-4 px-1 mb-2">
              <b>{row.amount}</b> <span className={`payout-status ${row.status}`}>{row.status}</span>
            </div>
            <div className="col-12 col-small-4 px-1 mb-2">
              {row.bankName}{` `}<br className="d-none d-small-block" /><span className="text-nowrap account-obfuscated">{row.last4}</span>
            </div>
            <div className="col-12 col-small-2 px-1 mb-2">
              <b className="d-small-none">Date:</b> {row.initiated}
            </div>
            <div className="col-12 col-small-2 px-1">
              <div className="ps-small-3">
                <b className="d-small-none">Est. arrival:</b> {row.arrivalDate}
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 col-small-2 px-1 text-end">
          <Button variant="secondary" onClick={() => { this.props.onClick(row.id); }}>View</Button>
        </div>
      </div>
    );
  }
}

export default PayoutRow;
